//Helper function to convert #hex colors to RGBA for rendering in SVGs with a given transparency

export function hexToRGBA(hex, alpha = 0.2) {
    // Remove the hash at the start if it's there
    hex = hex.replace('#', '');

    // Parse the hex color to get the RGB components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the RGBA color with 0.2 opacity
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}