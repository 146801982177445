import * as ReactDOMClient from "react-dom/client";

import "./index.css";

import SecuredApp from "./SecuredApp";

// import history from "./utils/history";
import reportWebVitals from './reportWebVitals';

// import * as serviceWorker from "./serviceWorker";
const container =  document.getElementById("root");

const root = ReactDOMClient.createRoot(container);

root.render( <SecuredApp /> );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();