import { useParams, Link } from "react-router-dom";
import { SiteAPI } from "../../../api"
import { useContext, useEffect, useState } from "react";

import { Typography, Collapse, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons"

import WellApi, { WellContext } from "../../../api/well";

import { PdecStatus } from "../../../components/Status/PdecStatus";

import { DepthTempChart } from "../../../components/Charts/DepthTempChart";

import {useDocumentTitle} from "../../../CustomHooks/useDocumentTitle";

import { ZoneTempChart } from "../../../components/Charts/ZoneTempChart";

import convertLatest from "../../../components/Charts/convertLatest";

import { UserContext } from "../../../api/user";

import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";

import "./site.css";

const { Title }  = Typography;
const { Panel } = Collapse;
 
const margins = {
  left: 50,
  right: 60,
  top: 20,
  bottom: 30
}

const WellSummary = ({site, well, latest})  => {
  const wellApi = WellApi(site, well);

  const [ depthTraces, setDepthTraces ] = useState([]);
  const [ zoneTraces, setZoneTraces ] = useState([]);

  const [ xLim, setXLim ] = useState();
  const [ yLim, setYLim ] = useState();
  const [ dateLim, setDateLim ] = useState();

  const [ tcs, setTcs ] = useState();

  useEffect(() => {
    wellApi.fetchInfo.sendRequest()
    wellApi.fetchZone.sendRequest({range: "1we"})

    return () => {
      wellApi.fetchInfo.cancel()
      wellApi.fetchZone.cancel()
    }
  }, [])

  useEffect(() => {
    if (!xLim && latest) {
      setXLim([0, latest.Span + latest.Start])
    }

    if (!yLim && latest) {
      setYLim([0, 300])
    }
  }, [latest, xLim, yLim])

  useEffect(() => {
    /*
    Still using the DTSRecord from api, all other traces that are requested are of the simpler form
    based on dtype style, so try to convert to something that works 

    The dead give-away is the captitalized items in the json object
    */

    /*
    Populate the traces with appropriate data
    */

    setDepthTraces(existing => {
      if (!existing.map(e => e.timestamp).includes(latest.Timestamp)) {

        return [ convertLatest(latest)]
      }
    })
  }, [latest])

  useEffect(() => {
    /* 
    Populate TCs, stripping out unused ones
    */

    const tcMap = wellApi.fetchInfo.data?.tcs;

    if (tcMap && latest) {
      let arr = [];

      for (let i in tcMap) {
        const tc = tcMap[i];

        if (tc.displayed) {
          arr.push(latest.TC[i])
        }
      }

      setTcs(existing => {
        if (existing?.key !== latest.Timestamp) {
          return { 
            key: latest.Timestamp,
            data: arr
          }
        }

        return existing
      })

    } 
  }, [ latest, wellApi.fetchInfo.data ])

  useEffect(() => {
    /*
    Populate zoneTraces with data from fetchZone
    */
    if (wellApi.fetchZone.data) {
      const data = wellApi.fetchZone.data;

      setZoneTraces(wellApi.fetchInfo.data?.zones.reduce(
        (acc, zone, i) => {
          if (zone.displayed) {
            acc.push({ 
                key: zone.name,
                xData: data[0],
                yData: data[1][i].map(p => p.mx),
                colour: "#a87532"
            })
          }
          return acc
        }, []));

      setDateLim([data[0][0], data[0][data[0].length - 1]]);
    }
  }, [wellApi.fetchZone.data, wellApi.fetchInfo.data]);

  return <>
          <WellContext.Provider value={wellApi}>
            <Collapse defaultActiveKey="latest">
              <Panel header={`${well} Latest`} key="latest">
                <div className="latestChart">
                  <DepthTempChart 
                      margins={margins}
                      traces={depthTraces}
                      xLim={xLim}
                      yLim={yLim}
                      tcs={tcs}
                      // title={`${well} - ${latest.Timestamp}`}
                      title={`${well} - ${adjustToBrowserTime(latest.Timestamp)}`}
                  />
                </div>
            </Panel>
            <Panel header={`${well} Zone vs Time`} key="zone">
              <div className="zoneChart">
                <ZoneTempChart
                  margins={margins}
                  traces={zoneTraces}
                  xLim={dateLim}
                  yLim={yLim}
                  title={`${well} Zone View`}
                />
              </div>
            </Panel>
           </Collapse>
          </WellContext.Provider>
      </>
}


const SiteDetailed = (props) => {
  let params = useParams();

  const siteAPI = SiteAPI(params.sitename)
  const { getSiteInfo } = siteAPI;

  
  const user = useContext(UserContext);

  useEffect(() => {
    getSiteInfo.sendRequest()
    // setDocumentTitle(params.sitename);
    const i = setInterval(getSiteInfo.sendRequest, 60*1000)
    // console.log('getsiteinfo',getSiteInfo.data)
    return () => {
      clearInterval(i)
      getSiteInfo.cancel()
    }
  }, [params.sitename])
  /* eslint-enable react-hooks/exhaustive-deps */

  return <>
    <Title level={3}>{getSiteInfo.data?.name} { user.admin && <Link to="edit"><EditOutlined /></Link>}</Title>
    <PdecStatus state={getSiteInfo.data?.state} />
    <Divider />
    { getSiteInfo.data?.wells?.map(w => {
      if (w.displayed) {
        return <div key={w.name}>
          <Link to={`/site/${params.sitename}/${w.name}`}>{w.name}</Link>
          <WellSummary site={getSiteInfo.data?.name} well={w.name} latest={w?.latest}/>
          <Divider />
        </div>
        }
      })
    } 
    </>

  /*
      <div key={w.name} className="zoneSiteCharts">
        <Zone site={params.sitename} well={w.name} />
      </div>
  useEffect(() => {
    if (!status) return;
    const zone_cols = [...Array(10).keys()].map(i => (
      {
        title: `Z${i + 1} Max`,
        dataIndex: 'Latest',
        render: z => z ? z.Zone[i].Max.toFixed(1) : 0,
      }
    ))
    const tc_cols = [...Array(10).keys()].map(i => (
      {
        title: `FO/TC${i + 1} diff`,
        dataIndex: 'Latest',
        render: z => z ? (z.TC[i].FOTemp - z.TC[i].TCTemp).toFixed(1) : 0
      }
    ))

    console.log(status)
    setColumns([
      {
        title: 'Name',
        dataIndex: 'Name',
        // render: linkRender 
        render: (key) => <Link to={site + "/well/" + key}>{key}</Link>
      },
      {
        title: 'Last',
        dataIndex: 'Latest',
        render: l => {
          return l.Timestamp 
        }
      },
      ...zone_cols,
      ...tc_cols,
      {
        title: 'Loss',
        dataIndex: 'Latest',
        render: l => l.Loss ? Math.min(...l.Loss.filter(l => l > -20)).toFixed(2) : 0 
  
      },
      {
        title: 'Max',
        dataIndex: 'Latest',
        render: l => l.Temp ? Math.max(...l.Temp).toFixed(2) : -273.15
      },
    ])
  }, [status])
  */
}

export default SiteDetailed;
