import "./tooltip.css";

import { useState, useEffect } from "react";

import { HexColorPicker } from "react-colorful";

import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";

import { 
    MinusOutlined, 
    BgColorsOutlined, 
    DownOutlined,
    UpOutlined,
    EyeOutlined, 
    EyeInvisibleOutlined
} from "@ant-design/icons";

/*
The xData object comes from doubleClick
ChartWithOverlay ~line 160

It then gets added in TempVDepth ~180

This could be more consistent but it works for now

Gross, I can't get to the formatter from TempVDepth so I'll probably have to do it from the chart....?
*/ 



export const ChartSummary = ({
    data,
    xData,
    updateXData
}) => {
    const updateDepthItem = (key, update, prop) => {
        const idx = xData.findIndex(t => t.key === key);

        switch(update) {
            case "colour":
                xData[idx].colour = prop;
                break;
            case "remove":
                xData.splice(idx, 1);
                break;
            case "visible":
                xData[idx].visible = prop;
                break;
            default:
                console.log("default")
        }

        updateXData && updateXData(xData)
    }

    return data ?  <div className="summary">
        { data.map(d => 
            <div className="summary-section" key={d.key}>
                <div className="summary-title">
                    <span>{d.key}</span>
                </div>
                { d.data.map(p => p.visible && <span key={p.key + d.x} className="summary-line" style={{color: p.colour}}>{adjustToBrowserTime(p.key)} {p.yLabel}</span> ) }
         </div>
        )}
        { xData?.map(d =>  
            <SummarySection key={d.key} data={d} updateDepthItem={updateDepthItem} />
        )}

    </div> : null
}

const SummarySection = ({ data, updateDepthItem }) => {
    const [ collapsed, setCollapsed ] = useState(false);
    const [ pickerVisible, setPickerVisible ] = useState(false);

    return (
        <div className="summary-section" style={{backgroundColor: data.colour, color: getContrastTextColor(data.colour)}}>
            <div className="summary-title">
                <span>{data.xLabel}</span>
                <div 
                    className="push-right" 
                    style={{backgroundColor: data.colour, color: getContrastTextColor(data.colour), position: 'relative'}}
                >
                    <BgColorsOutlined onClick={() => setPickerVisible(e => !e)} />

                    { pickerVisible && (
                        <div style={{position: "absolute", top: '20px', left: '30px', zIndex: 1000, left: '-170px', down: '40px'}}>
                            <HexColorPicker color={data.colour} onChange={(c) => updateDepthItem(data.key, "colour", c)} />
                        </div>
                    )}

                    <MinusOutlined onClick={() => updateDepthItem(data.key, "remove")}/>
                    {data.visible ?
                        <EyeOutlined onClick={() => updateDepthItem(data.key, "visible", false)}/> :
                        <EyeInvisibleOutlined onClick={() => updateDepthItem(data.key, "visible", true)}/> 
                    }
                    {!collapsed ? 
                        <DownOutlined onClick={() => setCollapsed(true)}/> : 
                        <UpOutlined onClick={() => setCollapsed(false)}/> 
                    }
                </div>
            </div>
            {!collapsed && data.data.map(p => p.visible && <SummaryItem key={p.key + data.key} data={p} />)}
        </div>
    );
}


const SummaryItem = ({ data }) => {
    const [ pickerVisible, setPickerVisible ] = useState(false);

    const onDragDropped = (e) => {
        e.preventDefault()
        e.target.style['border-top'] = '';
        e.target.style['border-bottom'] = '';
    }

    const onDragOver = (e) => {
        e.preventDefault()
        e.stopPropagation();

        if (e.target.className !== "trace-selection-line") return;

        var bounding = e.target.getBoundingClientRect()
        var offset = bounding.y + (bounding.height/2);
        if ( e.clientY - offset > 0 ) {
            e.target.style['border-bottom'] = 'solid 4px blue';
            e.target.style['border-top'] = '';
            // setTarget({key: trace.key, after: true})
        } else {
            e.target.style['border-top'] = 'solid 4px blue';
            e.target.style['border-bottom'] = '';
            // setTarget({key: trace.key, after: false})
        }

    }

    const onDragLeave = (e) => {
        e.target.style['border-top'] = '';
        e.target.style['border-bottom'] = '';
    }

    const onDragEnd = (e) => {
        // onDrop(trace.key)
    }

    return <div
        className="summary-line" 
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDragDropped}
        onDragEnd={onDragEnd}
        draggable
        key={data.key}
        >
        <span key={data.key} style={{color: data.colour}}>{adjustToBrowserTime(data.key)} {data.yLabel}</span>
        <div className="push-right">
        </div>
    </div>
}

function getContrastTextColor(colour){
    
    function isHexColor(c) {
        return /^#([0-9A-F]{3}){1,2}$/i.test(c);
    }

    function namedToHex(named) {
        let ctx = document.createElement('canvas').getContext('2d');
        ctx.fillStyle = named;
        return ctx.fillStyle;
    }

    function getTextColour(hex) {
        hex = hex.replace("#", "");
        const r = parseInt(hex.substr(0,2),16);
        const g = parseInt(hex.substr(2,2),16);
        const b = parseInt(hex.substr(4,2),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    if (isHexColor(colour)) {
        return getTextColour(colour)
    } else if (!colour){
        return "black"
    } 
    else return getTextColour(namedToHex(colour))
}