import React, { useRef, useState, useEffect } from 'react';
import { Card, Collapse } from 'antd';
import { Link } from "react-router-dom";
import './wellstatus.css';
import { ZoneStatus, TCStatus} from '../../Status';

import { WellAPI } from '../../../api';
import { WellContext } from "../../../api/well";

const { Panel } = Collapse;

const printMaxTempDepth = (record) => {
    if (!record.Temp) return null;
    if (record.Temp.length === 0) return -1;
    
    var max = record.Temp[0];
    var maxDepth = record.Start;
    
    for (var i = 1; i < record.Temp.length; i++) {
        if (record.Temp[i] > max) {
            maxDepth = record.Depth ? record.Depth[i] : record.Start + record.Span;
            max = record.Temp[i];
        }
    }
    
    return Math.round(max, 3) + "°C @ " + maxDepth + "mKB"
}

const WellStatus = ({well, site}) => {
    const [highlight, setHighlight] = useState(false);
    const firstUpdate = useRef(true);
    const [cardWidth, setWidth] = useState("300px");
    const latest = useRef({Timestamp: ""})

    const wellAPI = WellAPI(site, well); 

    const { fetchInfo } = wellAPI;

    useEffect(() => {
        fetchInfo.sendRequest() 
        return fetchInfo.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (well.Latest.Timestamp > latest.current.Timestamp) {          
            latest.current = well.Latest

            if (firstUpdate.current) {
                firstUpdate.current = false;
            } else {
                setHighlight(true);
            }
        } 
    },[well])

    const expandCard = (e) => {
        if (e.length > 0) {
            setWidth("600px");
        } else {
            setWidth("300px");
        }
    }
    console.log("WELL STATUS")

    return (
        <WellContext.Provider value={wellAPI}>
        <Card
            className={`${highlight ? "highlight" : ""}`}
            onAnimationEnd={() => setHighlight(false)}
            hoverable
            style={{width: cardWidth}}
            >
                <h1>{well.Name}</h1>
                <p>Latest: {well.Latest.Timestamp}</p>
                <p>Max Temp: {printMaxTempDepth(well.Latest)}</p>
                <Link to={"site/" + site + "/well/" + well.Name}>
                    {/* <SparkLine
                            rec={well.Latest} 
                            width={300} 
                            height={100} 
                            margin={10} 
                            color="red" 
                            style={{}} 
                    /> */}
                </Link>
            <Collapse onChange={expandCard}>
                <Panel header="Zone Summary" key="1">
                    <ZoneStatus zones={well.Latest.Zone} /> 
                </Panel>
                <Panel header="TC Summary" key="2">
                    <TCStatus tcs={well.Latest.TC} />
                </Panel>
            </Collapse>
        </Card>
        </WellContext.Provider>
        )
}

export default React.memo(WellStatus);