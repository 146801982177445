import { useEffect, useContext } from "react";
import { ChartContext } from "../Canvas/ChartContext";
import { CanvasContext } from "../Canvas/SVGCanvasOverlay";

const drawCurve = (
    context,
    h,
    w,
    m,
    c,
    t,
    width
) => {
    context.save();


    /* setup clip */
    context.beginPath();
    context.rect(m.left, m.top, w - m.left - m.right, h - m.top - m.bottom);
    context.clip();

    context.strokeStyle = c;
    context.lineWidth = width || 1;

    context.beginPath();
    context.moveTo(...t[0])

    for ( let p of t) {
        context.lineTo(...p)
    }

    context.stroke();
    context.restore();
}

export const Line = ({trace}) => {
    const { canvas, renderFrame } = useContext(CanvasContext);
    const { height, width, xScale, yScale, margins } = useContext(ChartContext);

    useEffect(() => {
        if (!trace || !canvas || !trace?.yData?.length || !yScale || !xScale) return;
        const points = trace.xData.map((x, i) => [xScale(x), yScale(trace.yData[i])]);
        const ctx = canvas.getContext("2d");
        drawCurve(ctx, height, width, margins, trace.colour, points, trace.width);
    }, [canvas, height, width, margins, xScale, yScale, trace, renderFrame]);

    return null
}