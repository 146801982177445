import getUserList from "../../api/admin";
import Users from "./users";
import { useContext, useState } from "react";
import { useDocumentTitle } from "../../CustomHooks/useDocumentTitle";
import { UserContext } from "../../api/user";

const Admin = () => {
    const usersApi = getUserList();

    const [users, setUsers ] = useState([]);

    /*
    useEffect(() => getUserList().then(r => {
        setUsers(r.data.users ? r.data.users : [])
    }), []);
    */

    const user = useContext(UserContext);

    useDocumentTitle("Admin");

    return <>
        <h1>Hello Admin, {user?.name}</h1>
        {/* <Users users={usersApi.data} /> */}
        <Users users={usersApi} />
    </>
}

export { Admin };

