// Functions to transform the API response for sections into the format expected by the frontend
// Edited to account for updating minDepth and maxDepth of sections when the depth limit is changed 
function createSection(item, [minDepth, maxDepth]) {
    const {
        doiStart,
        doiEnd,
        email,
        doiColor,
        doiLabel,
        doiDescription,
        doiLayerLabel,
        doiTimestamp,
        doiType,
        doiVisible,
        doiID
    } = item;

    return {
        start: doiStart,
        end: doiEnd || doiStart,
        createdBy: email,
        color: doiColor,
        label: doiLabel,
        description: doiDescription,
        minDepth,
        maxDepth,
        layerName: doiLayerLabel,
        creationDate: doiTimestamp,
        sectionType: doiType,
        doiVisible,
        doiID,
        storedInDB: true
    };
}

function updateSectionDepths(section, [minDepth, maxDepth]) {
    // Only update minDepth and maxDepth
    return {
        ...section,
        minDepth,
        maxDepth
    };
}

function isGroupedData(data) {
    // This is a basic check. You might need a more robust check based on your actual data structure.
    return Array.isArray(data) && data.every(item => item.hasOwnProperty('sections'));
}

function transformAPISchemaSections(data, depthLim, updateOnly = false) {
    if (!data) {
        return [];
    }

    if (updateOnly && isGroupedData(data)) {
        // Data is already grouped, just update the minDepth and maxDepth
        return data.map(layerData => ({
            ...layerData,
            sections: layerData.sections.map(section => updateSectionDepths(section, depthLim))
        }));
    } else {
        // Handle ungrouped data (i.e., raw API response)
        const groupedData = {};

        data.forEach(item => {
            const layerIDString = item.doiLayerLabel.toString();

            if (!groupedData[layerIDString]) {
                groupedData[layerIDString] = {
                    layerName: layerIDString,
                    isEnabled: item.doiVisible,
                    sections: []
                };
            }

            groupedData[layerIDString].sections.push(createSection(item, depthLim));
        });

        // Sort and return grouped data
        for (const layerIDString in groupedData) {
            groupedData[layerIDString].sections.sort((a, b) => {
                if (a.sectionType === 'section' && b.sectionType === 'point') return -1;
                if (a.sectionType === 'point' && b.sectionType === 'section') return 1;
                return 0;
            });
        }

        return Object.values(groupedData);
    }
}

export { transformAPISchemaSections };
