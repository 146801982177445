export const depthGen = (rec) => {
    let arr = [];

    for (let i = rec.start; i < rec.span + rec.start; i += rec.step) {
        arr.push(i)
    }
    return arr
}

export const depthLimits = (rec) => {
    if (!rec) return;
    if (!rec.start || !rec.span){
        return [rec.xData[0], rec.xData[rec.xData.length - 1]]
    }
    else {
        return [rec.start, rec.span + rec.start]
    }
}

export const depthZip = (rec) => {
    return rec.data.map((a, i) => ({depth: rec.start + i*rec.step, temp: a}))
}