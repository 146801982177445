import { Select, Button, InputNumber } from "antd";

import { useState, useContext } from "react";
import { WellContext } from "../../api/well";

const { Option } = Select;

export const FocusInput = ({}) => {
        const [mode, setMode] = useState();
        const [time, setTime] = useState(60);
        const [unit, setUnit] = useState("min");

        const { setFocus, wellName } = useContext(WellContext);

        const modeChange = (changedValue) => {
            setMode(changedValue)
        }

        const triggerChange = () => {
            if (mode) {
                let seconds = 0;

                switch (unit) {
                    case "min":
                        seconds = time*60;
                        break;
                    case "hour":
                        seconds = time*60*60;
                        break;
                    case "day":
                        seconds = time*60*60*24;
                        break;
                }

                let body = {};

                if (mode.includes("-timed")) {
                    body = {mode, seconds, well: wellName}
                } else {
                    body = {mode, well: wellName}
                }

                setFocus.sendRequest({data: body});
            }
        }

        const t_select = (
            <Select defaultValue={unit} style={{ width: 60 }} onChange={u => setUnit(u)}>
                <Option value="min">Min</Option>
                <Option value="hour">Hour</Option>
                <Option value="day">Day</Option>
            </Select>
        )

        return <>
        <Select value={mode || "Focus Mode"} style={{width: 300}} onChange={modeChange}>
            <Select.Option value="normal">Normal</Select.Option>
            <Select.Option value="quick">Quick Shot</Select.Option>
            <Select.Option value="lazy">Next Trace</Select.Option>
            <Select.Option value="focus">Exclusive Focus</Select.Option>
            <Select.Option value="focus-timed">Exclusive Focus - Timed</Select.Option>
            <Select.Option value="interleaved">Interleaved</Select.Option>
            <Select.Option value="interleaved-timed">Interleaved - Timed</Select.Option>
        </Select>
        { mode?.includes("-timed") && <>Minutes:<InputNumber addonAfter={t_select} defaultValue={time} onChange={x => setTime(x)}/></>}
        <Button onClick={triggerChange}>Send It!</Button>
        </>
}
