import React, { useState, useEffect, useContext } from "react";
import { WellContext } from "../../api/well";
import { UserContext } from '../../api/user';
import { useDBWellApi } from "../../api/DBWellApi";
import { Input, Button, List, Typography, Modal, message, Empty, Spin, Tooltip } from 'antd';

// Constants defining the note's character limits and preview
const MAX_CHARS = 150;  // Character limit
const MIN_CHARS = 3;  // Character limit
const MAX_PREVIEW_CHARS = 100;  // Define how many characters to show in the preview

const Notes = () => {
  // Get API and user info from context
  const wellAPI = useContext(WellContext);
  const { fetchInfo } = useContext(WellContext);
  const user = useContext(UserContext);

  // API hooks for adding and editing well notes
  const { addNewWellNote, editWellNote, deleteWellNote } = useDBWellApi();

  // State variables
  const [notes, setNotes] = useState([]);  // List of notes
  const [noteInput, setNoteInput] = useState("");  // Current input in the note text area
  const [charCount, setCharCount] = useState(0);  // Character count of the note input
  const [editIndex, setEditIndex] = useState(null);  // Index of the note being edited (null if adding a new note)
  const [currentPage, setCurrentPage] = useState(1);  // Current page for pagination
  const [expandedNoteIndex, setExpandedNoteIndex] = useState(null);  // Index of the note that is fully expanded
  const [wellNoteID, setWellNoteID] = useState(null);  // ID of the note being edited
  const [isLoading, setIsLoading] = useState(false);  // Loading state for fetching
  const [initialLoad, setInitialLoad] = useState(false);  // Initial load state for fetching
  

   // Function to toggle the full view of a note
  const toggleNote = (index) => {
    if (expandedNoteIndex === index) {
      setExpandedNoteIndex(null);
    } else {
      setExpandedNoteIndex(index);
    }
  };

  // Update the note input and its character count
  const handleInputChange = e => {
    setNoteInput(e.target.value);
    setCharCount(e.target.value.length);  // Set the character count
  };

  // for dev purposes
  // useEffect(() => {
  //   console.log("The current notes are:", notes);
  // }, [notes]);

  // Fetch the well notes from the database if notes tab is first active tab.
  useEffect(() => {
    fetchInfo.sendRequest().then(() => {
      setInitialLoad(true);
    }).catch(() => {
      setInitialLoad(false);
    });
  }, [ ]);


  useEffect(() => {
    if (fetchInfo.data?.wellnotes) {
      setNotes(fetchInfo.data.wellnotes);
    }
  }, [initialLoad]);

  // Create a note object from text
  const createNoteObject = (text, noteID, originalTimestamp = null) => ({
    noteText: text,
    noteTimestamp: originalTimestamp ? originalTimestamp : new Date().toISOString(),
    Email: user.email,
    deleted: null,
    noteID: noteID,
    version: new Date().toISOString(),
  });
  

  //  Function to handle the adding submission of a note to the database
  const handleAddSubmit = async () => {
    const wellNoteDTO = {
      noteText: noteInput.trim(),
      email: user.email,
      // email: 'test@email.com',
      wellName: wellAPI.wellName,
      wellID: wellAPI.fetchInfo.data.wellID
    };
    try {
      const response = await addNewWellNote({
        site: wellAPI.siteName,
        well: wellAPI.wellName,
        newNoteObject: wellNoteDTO
      });
      
      // console.log({response});
      return response.data.noteID; //for noteId in new note
    } catch (error) {
      // console.log("my request:", wellAPI.siteName, wellAPI.wellName, wellNoteDTO);
      console.log({error});
      throw error;
    }
  };

  const handleUpdate = async () => {
      const updatedWellNoteDTO = {
          NoteText: noteInput,
          Email: user.email,
      };
        
    // const noteID = wellNoteID;

    try {
      // console.log("Sending payload:", updatedWellNoteDTO);
      const response = await editWellNote({
        site: wellAPI.siteName,
        well: wellAPI.wellName,
        noteID: wellNoteID,
        updatedNoteObject: updatedWellNoteDTO
      });
      // console.log("note update",{response})
      message.success('Note updated successfully');
      setWellNoteID(null);
      setCharCount(0);
    } catch (error) {
      console.log({error})
      message.error('Error updating note');
    }
  };

  // Add a new note, uses handleAddSubmit() to add to the database
  const addNote = () => {
    if (noteInput.trim()) {
      setIsLoading(true);  // Start loading
      handleAddSubmit()
          .then(noteID => {  // use the returned noteID here
              const newNote = createNoteObject(noteInput.trim(), noteID); // Create a new note object to add to the notes array
              setNotes(prevNotes => [...prevNotes, newNote]);
              setNoteInput("");
              setEditIndex(null);
              message.success('Note added successfully');
              setIsLoading(false);  // End loading
              setCharCount(0);
          })
          .catch(() => {
            setIsLoading(false);  // End loading
            message.error('Error adding note');
          });
    }
  };

  // Update an existing note for local rendering to avoid refetching every time a note is updated.
  const updateNote = () => {
    if (noteInput.trim()) {
      const updatedNotes = [...notes];
      const originalTimestamp = updatedNotes[editIndex].noteTimestamp;
      updatedNotes[editIndex] = createNoteObject(noteInput.trim(), wellNoteID, originalTimestamp);
      setNotes(updatedNotes);
      setNoteInput("");
      setEditIndex(null);
      handleUpdate();
    }
  };

  //fordev
  // useEffect(() => {
  //   console.log ("use effect wellNoteID:", wellNoteID);
  // }, [wellNoteID]);

  // Function to delete a note from the database
  const handleDelete = async (noteIDToDelete) => {
    // console.log("using noteID:", noteIDToDelete);
    try {
        const response = await deleteWellNote({
            site: wellAPI.siteName,
            well: wellAPI.wellName,
            noteID: noteIDToDelete
        });
        // console.log({ response });
        if (response.status === 200) { // Check if the response status is 200 OK
            message.success(response.data.message || 'Note deleted successfully'); // Use the server message if available
        } else {
            message.error('Error deleting note'); // Generic error 
        }
        return response;
    } catch (error) {
        console.log({ error });
        message.error('Error deleting note'); // Error message for exceptions
        throw error;
    }
  };

  // Delete a note action- handledelete() will go here
  const deleteNote = (displayIndex) => {
    const actualIndex = notes.length - 1 - displayIndex;
    const currentNoteID = notes[actualIndex].noteID;
    Modal.confirm({
      title: 'Delete Note',
      content: 'Are you sure you want to delete this note?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
            await handleDelete(currentNoteID);
            const updatedNotes = [...notes];
            updatedNotes.splice(actualIndex, 1);
            setNotes(updatedNotes);
        } catch (error) {
            // This will handle if the delete operation failed
        }
      }
    });
  };

  // Edit a note visual index handler and displaying the update buttons/text in the input box
  const editNote = (displayIndex) => {
    const actualIndex = notes.length - 1 - displayIndex;  // Adjust for the reversed list rendering order of notes
    setNoteInput(notes[actualIndex].noteText);
    setEditIndex(actualIndex);
    setCharCount(notes[actualIndex].noteText.length);
    setWellNoteID(notes[actualIndex].noteID)
  };

  const cancelEdit = () => {
    setNoteInput("");
    setEditIndex(null);
    setWellNoteID(null);
  };

  // Pagination properties
  const paginationProps = {
    current: currentPage,
    pageSize: 10,
    total: notes.length,
    onChange: (page) => setCurrentPage(page),
  };
  
  return (
    <div style={{ padding: '24px' }}>
      <Typography.Title level={3}>
        Well Notes {isLoading && <Spin />}
      </Typography.Title>
      <div style={{ marginBottom: '16px' }}>
        <Input.TextArea
            rows={4}
            value={noteInput}
            onChange={handleInputChange}
            placeholder="Write a new note..."
          />
        <div style={{ marginTop: '8px' }}>
            <Typography.Text style={{ color: charCount > MAX_CHARS ? 'red' : 'black' }}>
              Characters {charCount} / {MAX_CHARS} {charCount > MAX_CHARS ? " (exceeded)" : ""}
            </Typography.Text>
          </div>
          {charCount < MIN_CHARS && 
            <div style={{ marginTop: '8px' }}>
              <Typography.Text type="warning">
                Note should be at least {MIN_CHARS} characters long.
              </Typography.Text>
            </div>
          }
          {noteInput.trim() === "" && charCount > 0 &&
            <div style={{ marginTop: '8px' }}>
              <Typography.Text type="warning">
                Note cannot be only whitespace. Please enter some text to create a note.
              </Typography.Text>
            </div>
          }
          <div style={{ marginTop: '12px', display: 'flex', justifyContent: 'left' }}>
            <Button 
              type="primary" 
              onClick={editIndex !== null ? updateNote : addNote} 
              disabled={charCount > MAX_CHARS || charCount < MIN_CHARS || noteInput.trim() === ""}>
              {editIndex !== null ? 'Update Note' : 'Add Note'}
            </Button>
            {editIndex !== null && 
              <Button onClick={cancelEdit}>
                Cancel
              </Button>
            }
          </div>
        </div>
      <List
        bordered
        dataSource={notes.slice().reverse()} // Reverse the list to show the latest notes first
        renderItem={(note, index) => {
          const isUserNote = note.Email === user.email; //used to validate which note is the user's for edit/delete
          
          return (
            <List.Item
              actions={[
                <Tooltip title={isUserNote ? "" : "You can only edit your own notes"}>
                  <Button 
                    type="primary" 
                    onClick={() => isUserNote && editNote(index)}
                    disabled={!isUserNote}
                  >
                    Edit
                  </Button>
                </Tooltip>,
                <Tooltip title={isUserNote ? "" : "You can only delete your own notes"}>
                  <Button 
                    type="secondary" 
                    onClick={() => isUserNote && deleteNote(index)}
                    disabled={!isUserNote}
                  >
                    Delete
                  </Button>
                </Tooltip>
              ]}
              onClick={() => toggleNote(index)}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  <Typography.Text>
                    {expandedNoteIndex === index || note.noteText.length <= MAX_PREVIEW_CHARS
                      ? note.noteText
                      : `${note.noteText.substring(0, MAX_PREVIEW_CHARS)}...`}
                  </Typography.Text>
                </div>
                <div>
                  <Typography.Text type="secondary">
                    Note created by {note.Email}, date: {new Date(note.noteTimestamp).toLocaleString()}
                  </Typography.Text>
                </div>
              </div>
            </List.Item>
          );
        }}
        locale={{ emptyText: <Empty description="This well has no notes. Add a new one!" /> }}
        pagination={paginationProps}
      />
    </div>
  );
 
};

export default Notes;