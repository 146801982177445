import { useEffect, createContext, useContext, useRef, useState, useId } from "react";

import { ChartContext } from "./ChartContext";

import './canvas.css';

export const CanvasContext = createContext();

const ClearCanvas = ({rendered}) => {
    /*
    I had to seperate the ClearCanvas so react would fire in the proper order,
    this component needs to sit above the other children so it is the first to fire
    */

    const {canvas} = useContext(CanvasContext);

    const { height, width, title, xScale, yScale, margins, traces } = useContext(ChartContext);

    /*
    useEffect(() => console.log("width change"), [width]);
    useEffect(() => console.log("margin change"), [margins]);
    useEffect(() => console.log("xScale change"), [xScale]);
    useEffect(() => console.log("yScale change"), [yScale]);
    */

    useEffect(() => {
        if (!canvas) return;
        const ctx = canvas.getContext("2d");

        const l = margins.left; 
        const t = margins.top;
        const w = width - margins.left - margins.right; 
        const h = height - margins.bottom - margins.top;

        ctx.fillStyle = "#F2F2F2";
        ctx.fillRect(0, 0, width, height);

        ctx.fillStyle = "#D8D8D8";
        ctx.strokeStyle = "black";
        ctx.beginPath();
        ctx.rect(l, t, w, h);
        ctx.fill();
        ctx.stroke();

        ctx.font = `12px Arial`;

        rendered();
    }, [ title, xScale, yScale, traces ]);

    return null
}

export const SVGCanvasOverlay = ({
    onDoubleClick,
    children
}) => {
    const canvasRef = useRef();
    const svgRef = useRef();
    const svgClip = useId();

    const [renderFrame, setRenderFrame] = useState(0);

    const { height, width, margins, title } = useContext(ChartContext);
    
    // useEffect(() => console.log("SVGCanvasOverlay rendered", title, renderFrame), [renderFrame, title])

    const incrementRenderFrame = () => setRenderFrame(e => e += 1);

    return  <div className="svgCanvasOverlay">
                <canvas 
                    ref={canvasRef} 
                    height={height} 
                    width={width} 
                    />
                <svg 
                    ref={svgRef} 
                    height={height} 
                    width={width}
                    onDoubleClick={onDoubleClick}
                >
                <defs>
                <clipPath id={svgClip}>
                  <rect 
                    x={margins.left} 
                    y={margins.top} 
                    height={height - margins.top - margins.bottom} 
                    width={width - margins.left - margins.right}
                    />
                </clipPath>
                </defs>
                    <CanvasContext.Provider value={{
                            canvas: canvasRef.current, 
                            svg: svgRef.current,
                            clipId: svgClip,
                            renderFrame
                        }}>
                        <ClearCanvas rendered={incrementRenderFrame} />
                        {children}
                    </CanvasContext.Provider>
                </svg>
            </div>
}
