import  { ChartWithOverlay } from "./ChartWithOverlay";

export const DepthLossChart = ({
    title,
    margins,
    xLim,
    yLim,
    traces,
    highlighted,
    xLines,
    setXLines,
    setCursorData,
    updateDepths
}) => {
    return <ChartWithOverlay 
    margins={margins}
    xLim={xLim}
    yLim={yLim}
    title={title}
    traces={traces}
    xUnit="mKB"
    yUnit="dB"
    highlighted={highlighted}
    setCursorData={setCursorData}
    xLines={xLines}
    setXLines={setXLines}
    onDoubleClick={updateDepths}
    xFormatter={(d) => `${Math.round(d)} mKB`}
    yFormatter={(t) => `${t} dB`}
    useCrosshairs={true}
/>
}

