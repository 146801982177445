import { Route, Routes, Navigate } from 'react-router-dom';
import { UserContext } from '../api/user';
import { Admin } from '../components/Admin';
import { UserHome } from '../pages/User/home';
import { HealthStatus } from '../pages/DTS/HealthStatus';
import { AllPdecStatus } from '../components/Status/PdecStatus';
import { SiteEdit, SiteDetailed, WellDetail, WellEdit } from "../pages/DTS"
import { Result } from 'antd';

import { useContext } from 'react';

import UserProfile from '../components/User/profile';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import  SupportPage from "../components/Support/support"

const ForbiddenComponent = (props) => <Result status="403" title="403" />
const NotFoundComponent = (props) => <Result status="404" title="404" />

const AppRoutes = () => {

    const user = useContext(UserContext);

    return(
        <Routes>
            <Route path = "/" element = { <Navigate to = "/home"/> } />
            <Route path = "/home" element = { <UserHome /> } />
            <Route path = "/user" element = { <UserProfile /> } />
            <Route path = "/support" element = { <SupportPage/> } />
            <Route path = "/healthreport" element = { <HealthStatus />}/>
            <Route path = "/pdecstatus" element = { <AllPdecStatus />}/>
            <Route path="/site/:sitename" element={<SiteDetailed />}/>
            <Route path="/site/:sitename/edit" element={<SiteEdit />}/>
            <Route path="/site/:sitename/:wellname" element={<WellDetail />}/>
            <Route path="/site/:sitename/:wellname/edit" element={<WellEdit />}/>
            <Route path="/admin" element={ user?.admin && <Admin /> } />
            <Route path="/login" element={ <Login />} />
            <Route path="/logout" element={ <Logout /> } />
            <Route path="/403" exact element={<ForbiddenComponent />}/>
            <Route element={<NotFoundComponent />}/>
        </Routes>
    );
}

export default AppRoutes;