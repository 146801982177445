import ApiRequest from './request';
import { createContext } from 'react';

import { TCData } from './tc';
import { SetFocus } from './focus';

/*
if well name is provided in lower case, it will be converted to upper case for retrival consistency...
account for the wells with Kirby in the name, and spaces */

const normalizeWellName =(well) => {
    return well.includes(' ') ? well : well.toUpperCase()
}

const ZoneFetch = (site, well) => {
    well = normalizeWellName(well);

    return ApiRequest({
        
        url: `/dts/site/${site}/${well}/zone`,
        transformResponse: (data) => {
            let resp 

            try {
                resp = JSON.parse(data)
            } catch (error) {
                throw Error(`Could not parse response: ${JSON.stringify(error)}`)
            } 

            const zoneLength = 10;

            const timestamps = resp.timestamps.map(ts => new Date(ts))

            let z = new Array(zoneLength).fill(null).map(() => [])

            if (!timestamps.length) {
                throw Error(`No timestamps returned: ${data}`)
            }

            for ( let zone of resp.data ) {
                for ( let i = 0; i < zoneLength; i++ ) {
                    z[i].push(zone[i])
                }
            }

            return [timestamps, z]
        }    
    }) 
};

const WellMeta = (site, well) => { well =  normalizeWellName(well); return ApiRequest({url: `/dts/site/${site}/${well}`}) }
const TraceFetch = (site, well) => { well =  normalizeWellName(well); return ApiRequest({url: `/dts/site/${site}/${well}/timestamp`}) }
const MultiTraceFetch = (site, well) => { well =  normalizeWellName(well); return  ApiRequest({url: `/dts/site/${site}/${well}/timerange`}) }
const RefreshData = (site, well) => { well =  normalizeWellName(well); return  ApiRequest({url: `/dts/site/${site}/${well}/reload`}) }
// const FetchDepth = (site, well) => ApiRequest({url: `dts/site/${site}/${well}/depth`})
const QueryTimestamps = (site, well) => { well =  normalizeWellName(well); return ApiRequest({
    url: `dts/site/${site}/${well}/query/timestamps`,
    /*
    I think now I'm just turning a list of timestamps... not sure if I really need the error
    transformResponse: (data) => {
        let resp

        try {
            resp = JSON.parse(data)
        } catch (error) {
            throw Error(`Could not parse response: ${JSON.stringify(error)}`)
        }

        const timestamps = resp.timestamps.map(ts => new Date(ts))
        
        if (!timestamps.length) {
            throw Error(`No timestamps returned: ${data}`)
        }

        return timestamps

    }
    */
})
}

const fetchMinMax = (site, well) => { well =  normalizeWellName(well); 
    return  ApiRequest({
    url: `/dts/site/${site}/${well}/zone`,
    transformResponse: (data) => {
        let resp
        
        try {
            resp = JSON.parse(data)
        } catch (error) {
            throw Error(`Could not parse response: ${JSON.stringify(error)}`)
        }
        

        const timestamps = resp.timestamps.map(ts => new Date(ts))
        
        let z = new Array();

        if (!timestamps.length) {
            throw Error(`No timestamps returned: ${data}`)
        }

        for ( let i in resp.data ) {
            let zone = resp.data[i];
            z.push({
                timestamp: timestamps[i],
                max: Math.max(...zone.map(z => z.mx)),
                min: Math.min(...zone.filter(z => z.b > 50).map(z => z.mn))
            })
        }
        return z 
    }
})
}

const FetchDepth = (site, well) =>{ well =  normalizeWellName(well); 
    return  ApiRequest({
    url: `/dts/site/${site}/${well}/depth`,
    transformResponse: (data) => {
        let resp
        try {
            resp = JSON.parse(data)
        } catch (error) {
            throw Error(`Could not parse response: ${JSON.stringify(error)}`)
        }
        
        const timestamps = resp.timestamps.map(ts => new Date(ts))
        
        let z = new Array();

        if (!timestamps.length) {
            throw Error(`No timestamps returned: ${data}`)
        }
        // for ( let i in resp.data ) {
        //     let zone = resp.data[i];
        //     z.push({
        //         timestamp: timestamps[i],
        //         max: Math.max(...zone.map(z => z.mx)),
        //         min: Math.min(...zone.filter(z => z.b > 50).map(z => z.mn))
        //     })
        // }
        return resp 
    }
})
}

const WellUpdate = (site, well) => { well =  normalizeWellName(well); 
    return  ApiRequest({
    url: `/dts/site/${site}/${well}/info`,
    method: 'post'
})
}

const FetchLatest = (site, well) => { well =  normalizeWellName(well); return ApiRequest({url: `/dts/site/${site}/${well}/latest`}) }

const FetchDepthOfInterest = (site, well) => { well =  normalizeWellName(well); return ApiRequest({url: `/dts/site/${site}/${well}/doi`}) }


export const WellContext = createContext(null);

const WellApi = (site, well) => ({
        fetchDepthOfInterest: FetchDepthOfInterest(site,well),
        fetchZone: ZoneFetch(site, well), 
        fetchInfo: WellMeta(site, well), 
        fetchTrace: TraceFetch(site, well),
        fetchTraceMultiple: MultiTraceFetch(site, well),
        fetchLatest: FetchLatest(site, well),
        fetchTCData: TCData(site, well),
        fetchDepth: FetchDepth(site, well),
        fetchMinMax: fetchMinMax(site, well),
        updateInfo: WellUpdate(site, well),
        reloadData: RefreshData(site, well),
        queryTimestamps: QueryTimestamps(site, well),
        setFocus: SetFocus(site, well),
        siteName: site,
        wellName: normalizeWellName(well)
    })

export default WellApi;