// const getUserList = () => Request({
//     url: '/admin/users'
// })
// Just a placeholder until the API's get written
const getUserList = () => {
    return (
        [
        {
            data: {
                email: "petrospec.engineering@cnrl.com",
                name: "Petrospec Engineering",
                admin: true,
                pads: [
                    "JACKFISH_R",
                    "KirbySouthPadC",
                    "PAW77",
                    "PAW92",
                    "Primrose Pad90",
                    "PrimrosePad22",
                    "PrimrosePad24",
                    "cnrl-JF-EEE",
                    "cnrl-JF-F",
                    "cnrl-JF-G",
                    "cnrl-JF-K",
                    "cnrl-JF-O",
                    "cnrl-JF-OO",
                    "cnrl-JF-PP",
                    "cnrl-KS-A",
                    "cnrl-PAW78"
                ]
            },
            loading: false,
            error: "",
            args: {
                url: "/user"
            }
        },
        {
            data: {
                email: "john.candy@cnrl.com",
                name: "John Candy",
                admin: false,
                pads: [
                    "JACKFISH_R",
                    "KirbySouthPadC",
                    "PAW77",
                    "PAW92",
                    "Primrose Pad90",
                    "PrimrosePad22",
                    "PrimrosePad24",
                ]
            },
            loading: false,
            error: "",
            args: {
                url: "/user"
            }
        },
        {
            data: {
                email: "rick.moranis@cnrl.com",
                name: "Rick Moranis",
                admin: false,
                pads: [
                    "cnrl-JF-EEE",
                    "cnrl-JF-F",
                    "cnrl-JF-G",
                    "cnrl-JF-K",
                    "cnrl-JF-O",
                    "cnrl-JF-OO",
                    "cnrl-JF-PP",
                    "cnrl-KS-A",
                    "cnrl-PAW78"
                ]
            },
            loading: false,
            error: "",
            args: {
                url: "/user"
            }
        },
        ]
    )
}
export default getUserList; 