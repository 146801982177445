import { useCallback, useEffect } from "react";
import * as d3 from "d3";
import { useResizer } from "../Hooks/Resizer";
import { SVGCanvasOverlay } from "../Canvas/SVGCanvasOverlay";

import './selector.css';

import { ChartContext } from "../Canvas/ChartContext";

const defaultMargins = {
    top: 20, 
    bottom: 30, 
    left: 30, 
    right: 5 
}

export const Selector = ({
    title,
    xLim,
    yLim,
    children,
    yFormat,
    margins=defaultMargins
}) => {
    const [ width, height, sizeRef ] = useResizer();

    const xScale = useCallback(xLim ? d3.scaleLinear()
        .range([margins.left, width - margins.right])
        .domain(xLim)
        : null
    , [width, xLim, margins]);

    const yScale = useCallback(yLim ? d3.scaleLinear()
        .range([height - margins.bottom, margins.top])
        .domain(yLim)
        : null
    , [height, yLim, margins]);

    return <>
            <div  className="selector chart" ref={sizeRef}>
                <ChartContext.Provider value={{
                    title,
                    width,
                    height,
                    margins,
                    xScale,
                    yScale,
                    yFormat
                }}> 
                    <SVGCanvasOverlay>
                        {children}
                    </SVGCanvasOverlay>
                </ChartContext.Provider>
            </div>
        </>
}