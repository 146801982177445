import { useMemo, useCallback, useState, useEffect } from "react";
import { useResizer } from "./Hooks/Resizer";
import { SVGCanvasOverlay } from "./Canvas/SVGCanvasOverlay";
import { depthGen } from "../../utils/depth";

import * as d3 from "d3";

import { Title } from "./Labels/Title";
import { ChartContext } from "./Canvas/ChartContext";
import { XAxis } from "./Axes/XAxis";
import { YAxis } from "./Axes/YAxis";
import { XLines } from "./Tooltip/XLines";
import { TCPoints } from "./Trace/TC";
import { Line } from "./Trace/Line";
import { Cursor } from "./Tooltip/Cursor";

import { shortDate } from "../../utils/date";

const defaultMargins = {
    top: 20,
    bottom: 30,
    left: 50,
    right: 50 
}

export const Chart = ({
    title,
    margins=defaultMargins,
    xScaleType="linear",
    xLim,
    yLim,
    traces,
    xLines,
    tcs,
    yUnit,
    xUnit,
    xLabel,
    setCursorData,
    children
}) => {
    const [ width, height, ref ] = useResizer();
    const [ cursor, setCursor ] = useState();

    // Setup x Scale and x display format
    // const xScale = useCallback(() => {

    const xScale = useMemo(() => {
        var scale;

        switch (xScaleType) {
            case "time":
                scale = d3.scaleTime();
                break;
            default:
                scale = d3.scaleLinear();
                break;
        }

        if (!xLim) return scale;

        const s = scale
            .range([margins.left, width - margins.right])
            .domain(xLim)

        return s
    }, [width, margins, xLim]);

    // xScale.tickFormat() returns original tick formatter.... so here's my kludge

    const xFormat = useCallback(d => {
        if (xScaleType == "time") return shortDate(d);
        return `${Math.round(d)} ${xUnit}`;
    }, [xUnit, xScale]);

    // Setup y Scale and y display format

    const yScale = useCallback(yLim && d3.scaleLinear()
            .range([height - margins.bottom,  margins.top])
            .domain(yLim)
    , [height, margins, yLim] );

    const yFormat = useCallback(t => `${t} ${yUnit}`, [yUnit]);

    const onCursorMove = useCallback((e) => {
        if (!xScale ||  ! yScale) return;
        setCursor(e)

        // I had to stick this in there to get the data back outside the context provider
        if (traces?.length && e) {
            const depths = depthGen(traces[0]);
            const i = d3.bisect(depths, e.x);

            setCursorData && setCursorData({
                x: depths[i],
                data: traces.map(t => (
                    {
                        ts: new Date(t.timestamp), 
                        y: t.data[i]
                    }
                ))
            })
        } else {
            setCursorData && setCursorData(null)
        }
    }, [xScale, yScale, traces])

    return  <div ref={ref} className="chart">
                <ChartContext.Provider value={{
                    height,
                    width,
                    margins,
                    title,
                    xScale,
                    yScale,
                    xFormat,
                    yFormat,
                    traces,
                    cursor,
                    onCursorMove,
                    xLines
                }}>
                    <SVGCanvasOverlay>
                        <Title title={title} />
                        <XAxis />
                        <YAxis />
                        <XLines />
                        { traces?.map(t => <Line key={t.key} trace={t} />) }
                        <TCPoints tcs={tcs} />
                        {/* <Cursor /> */}
                        { children }
                    </SVGCanvasOverlay>
                </ChartContext.Provider>
            </div>
}

