import { createContext } from "react";

/* Chart context will typically contain the following:
{
    Display properties of the chart:
    height,
    width, 
    margins,


    Chart scales
    xScale,
    yScale,

    Text formatting for axis and tooltip display
    xFormat: d => `${Math.round(d)} ${xUnit}`,

    Cursor position when hovering
    cursor,
    onCursorMove,

    Data to display
    traces,

    Annotations and depth highlights
    xLines,

    I don't remember what setXOverlay does....
    setXOverlay
}
*/

export const ChartContext = createContext();