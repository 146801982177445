import useApiClient from './useApi';

// Used to interact with wellnote data.
const useDBWellApi = () => {
  const apiClient = useApiClient();

  const addNewWellNote = ({ site, well, newNoteObject }) => {
    return apiClient.post(`/dts/site/${site}/${well}/wellnote/add`, newNoteObject);
  };
  
  const editWellNote = ({ site, well, noteID, updatedNoteObject }) => {
    return apiClient.patch(`/dts/site/${site}/${well}/wellnote/update/${noteID}`, updatedNoteObject);
  };

  const deleteWellNote = ({ site, well, noteID }) => {
    return apiClient.delete(`/dts/site/${site}/${well}/wellnote/delete/${noteID}`);
  };

  const addNewDOI = ({ site, well, newDOIObject }) => {
    return apiClient.post(`/dts/site/${site}/${well}/doi/add`, newDOIObject);
  }

  const editDOI = ({ site, well, doiID, updatedDOIObject }) => {
    return apiClient.patch(`/dts/site/${site}/${well}/doi/update/${doiID}`, updatedDOIObject);
  };

  const deleteDOI = ({ site, well, doiID }) => {
    return apiClient.delete(`/dts/site/${site}/${well}/doi/delete/${doiID}`);
  };
  
    return {  
      addNewWellNote, 
      editWellNote,
      addNewDOI, 
      editDOI,
      deleteWellNote,
      deleteDOI
    };
};

export { useDBWellApi };
