import { ChartWithOverlay } from "./ChartWithOverlay"                    

export const ZoneTempChart = ({ xLim, yLim, onDoubleClick, traces }) => {
    return <ChartWithOverlay 
        xScaleType="time"
        xLim={xLim}
        yLim={yLim}
        title="Zone Temperatures"
        traces={traces}
        xUnit=""
        yUnit="℃"
        onDoubleClick={onDoubleClick}
    />
}