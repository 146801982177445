import { useState, useEffect, useCallback, useContext, useMemo, useRef } from "react";

import { TimeSelector, DepthSelector } from "../Selectors"
import { DepthTempChart } from "../DepthTempChart";

import { useTempSelector, useCursorData } from "../Hooks";

import { depthLimits } from "../../../utils/depth";

import WellApi, { WellContext } from "../../../api/well";
import ApiRequest from "../../../api/request";
import { ChartSummary } from "../Tooltip/Summary";
import { SelectedList } from "../Selectors/SelectedList";
import CSVDownload from "../../Misc/CSVDownload";
import { Tooltip, Modal } from "antd";
import { EditOutlined, LineChartOutlined, BgColorsOutlined} from "@ant-design/icons";

import  convertTrace from "../convert";

import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";

import { transformAPISchemaSections } from "../../../utils/transformAPISchemaSections";

import './layout.css';

import { Switch, Spin } from "antd";

import { SchemaBarContainer } from "../SchemaBar";

import { plotSection, plotPOI } from "../../../utils/plotSchemaSections";


const margins = {
        top: 20,
        bottom: 30,
        left: 60,
        right: 55
    }

const styles = {
    container: {
      display: "grid",
      gridTemplateColumns: "250px 1fr 150px",
      gridTemplateRows: "200px 1fr 200px",
      height: "90vh",
    },
    leftColumn: {
      position: "relative",
      gridColumn: "1 / 2",
      gridRow: "1 / 4", // Adjusted to span two rows
      display: "flex",
      flexDirection: "column",
      backgroundColor: "lightgrey",
      textShadow: "0 0 0px rgba(255,255,255, 1)",
      fontWeight: "bold",
    
    },
    chartSummary: {
        flex: "3",
        overflow: "auto", 
      },
    leftColumnBottom: {
      position: "absolute",
      backgroundColor: "lightgrey",
      bottom: "0",
      width: "100%",
      height: "195px",
    },
    middleColumnTop: {
      gridColumn: "2 / 3",
      gridRow: "1 / 2",
    },
    chart: {
      gridColumn: "2 / 3",
      gridRow: "2 / 3",
      backgroundColor: "grey",
    },
    schemaBarContainer: {
        gridColumn: "2 / 3",
        gridRow: "3 / 4",
        backgroundColor: "#f2f2f2",
        position: 'relative',
        zIndex: 2,
        overflow: 'visible'
    },
    depthTrack: {
      gridColumn: "2 / 3",
      gridRow: "3 / 4",
      backgroundColor: "lightgrey",
    },
    tempTrack: {
      gridColumn: "3 / 4",
      gridRow: "2 / 3",
      backgroundColor: "lightgrey",
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', 
    }
};
      
export const TempVDepthLayout = ({ onEditClick, wellSchema, activeTab
}) => {
    const wellAPI = useContext(WellContext);
    const { fetchInfo, fetchTrace, fetchZone, queryTimestamps, fetchMinMax, fetchDepth } = wellAPI;

    const [ depthLim, setDepthLim ] = useState();
    const [ xLines, setXLines ] = useState([]);

    const [ currentTrace, setCurrentTrace ] = useState();
    const [ depthTraces, setDepthTraces ] = useState([]);

    //toggle for keeping temperature y-axis min/max
    const [lockYAxis, setLockYAxis] = useState(false);

    const [ tempLim, setTempLim, TempSelector ] = useTempSelector( depthTraces, margins);

    //State to use with when lockedYAxis
    const [ lockedTempLim, setLockedTempLim ] = useState(null);

    const [ cursorData, setCursorData ] = useCursorData(depthTraces);

    const [ depthSelectorTempLims, setdepthSelectorTempLims] = useState([-40,280])
    const [ depthExtents, setDepthExtents ] = useState([0, 1]);

    // const [ selectedTrace, setSelectedTrace ] = useState(wellAPI?.fetchTrace?.currentRequest?.timestamp)
    const [ selectedTrace, setSelectedTrace ] = useState()

    const fetchHighlight = ApiRequest(wellAPI.fetchTrace.args)
    const [ TimeSelectorTraces, setTimeSelectorTraces ] = useState();

    const [ selectedTCs, setSelectedTCs ] = useState();

    const [csvData, setCSVData] = useState();

    const TCFetch = fetchTrace.copy();

    const [ timeSelectorTempLims, settimeSelectorTempLims ] = useState();

    const [ schemaBarWidth, setSchemaBarWidth ] = useState();

    const [ schemaSections, setSchemaSections ] = useState(wellSchema);

    const [ toggledLayers, setToggledLayers ] = useState({}); //used for icon color change

    const [ showXLines, setShowXLines ] = useState(true);

    const [ isLoading, setIsLoading ] = useState(false);

    //function to provide the schema bar with the appropriate width if the browser changes size
    function handleWidthUpdate(width) {
        setSchemaBarWidth(width);
    }

    useEffect(() => {
        if (queryTimestamps.data) {
            // console.log("queryTimestamps.data", queryTimestamps.data)
            fetchZone.sendRequest({start: queryTimestamps.data[0], end: queryTimestamps.data[queryTimestamps.data.length - 1]})
            
            return () => {
                fetchZone.cancel();
            }
        }
    }, [queryTimestamps.data])

    //Save tempLims to as current values
    useEffect(() => {
        if (lockYAxis) {
            setLockedTempLim(tempLim);
            // console.log("lockedTempLim: ",lockedTempLim)
        }
    }, [lockYAxis]);

    useEffect(() => {
        wellAPI.fetchDepthOfInterest.sendRequest();
    }, []);

    // Update depthLimits and depthExtents
    useEffect(() => {
        if (currentTrace) {
            let newDl = depthLimits(currentTrace)
            // newDl[0] = 0;
            
            setDepthExtents(existing => {
                for (let i in existing) {
                    if (existing[i] != newDl[i]) return newDl 
                }
                return existing
            })

            if (!depthLim) {
                setDepthLim(newDl)
                
            } 
            
            if (!selectedTrace && currentTrace?.key) {
                setSelectedTrace(new Date(currentTrace.key))
            }
        }
    }, [currentTrace])

    // Load the latest trace and update currentTrace to start
    useEffect(() => {
        wellAPI.fetchLatest.data && setCurrentTrace(convertTrace(wellAPI.fetchLatest.data));
    }, [wellAPI.fetchLatest.data])

    

    useEffect(() => {
        //initialise schemaSections state from DB when page is loaded
        if (wellAPI.fetchDepthOfInterest.data && depthLim) {
            setSchemaSections(transformAPISchemaSections(wellAPI.fetchDepthOfInterest.data.allDepthOfInterest_DTO, depthLim));
        }
    }, [wellAPI.fetchDepthOfInterest.data]);

    // If selected moves send a request for the new trace
    useEffect(() =>{
        if (!selectedTrace) return;
        fetchHighlight.sendRequest({timestamp: selectedTrace, dtype: "temp"})
        return fetchHighlight.cancel
    }, [selectedTrace])

    // Add some limits if there aren't any alrady
    useEffect(() => {
        if (!depthLim && currentTrace) {
          setDepthLim(depthLimits(currentTrace))
        }
    
        if (!tempLim && currentTrace && !lockYAxis) {
            
            setTempLim([0, 300])
        }

    }, [currentTrace, lockYAxis])

    // Update the const once the API call has been made to prevent overrriding on depthLim update
    const initialScheamaSectionsAPIcall = useRef(false);

    //updating the maxDepth and minDepth when the depthLim is updated (true flag)
    useEffect(() => {
        if(!depthLim) return;
        setSchemaSections(transformAPISchemaSections(schemaSections, depthLim, true));
    }, [ depthLim ]);

    //updating the schemaSections state from DB when page is loaded and depthLim is set
    useEffect(() => {
        // console.log('schemaSections in tempvdepth - every depthLim', schemaSections)
        if (initialScheamaSectionsAPIcall.current || !depthLim) {
            return;
        }

        if (wellAPI.fetchDepthOfInterest.data && depthLim) {
            setSchemaSections(transformAPISchemaSections(wellAPI.fetchDepthOfInterest.data.allDepthOfInterest_DTO, depthLim));
            initialScheamaSectionsAPIcall.current = true; //set to true so that API call is not triggered on every depthLim update
        }
    }, [ depthLim ]);

    // Set the tempLims to to always always have all traces fully in view within depth section
    useEffect(() => {

        if (lockYAxis) return;  // Do nothing if Y-axis is locked

        if(!currentTrace) return;
        if(depthTraces.length===0) return;
        let yIndexArr = [];
        for (let i = 0; i < depthTraces.length; i++) {
            let xDataArr = depthTraces[i]["xData"];
            let yDataArr = depthTraces[i]["yData"];
            let startIndex = xDataArr.indexOf(Math.round(depthLim[0]));
            let endIndex = (xDataArr.indexOf(Math.round(depthLim[1])) === -1) ? ((depthTraces[0]?.xData?.length-1)) : (xDataArr.indexOf(Math.round(depthLim[1])));
            let tempXIndexArr = xDataArr.slice(startIndex, endIndex + 1);
            let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
            yIndexArr = yIndexArr.concat(tempYIndexArr);
   
        }
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);
        
        if (minValue < -20) minValue = -20;

        if (selectedTCs?.data?.length) {
            var tcMax = Math.max(...selectedTCs.data.map(tc => tc.TCTemp));
        }

        //want templims to be above TCs if they are higher temp than the fiber
        setTempLim([minValue*0.995, (selectedTCs?.data?.length && maxValue < tcMax) ? tcMax  * 1.03 : maxValue * 1.03]); 
        // setdepthSelectorTempLims([minValue*0.995, maxValue*1.04])


    }, [depthLim, depthTraces, lockYAxis]);
      

    useEffect(() => {
        if (lockYAxis || !currentTrace || depthTraces.length === 0) return;
    
        // Initialize min and max depth with the first trace's xData for a starting comparison point.
        let minDepth = Math.min(...depthTraces[0].xData);
        let maxDepth = Math.max(...depthTraces[0].xData);
    
        // Initialize yIndexArr outside the loop to accumulate all yData values.
        let yIndexArr = [];
    
        depthTraces.forEach(trace => {
            const { xData, yData } = trace;
    
            // Update minDepth and maxDepth if the current trace's xData has smaller or larger values respectively.
            minDepth = Math.min(minDepth, ...xData);
            maxDepth = Math.max(maxDepth, ...xData);
    
            yIndexArr = yIndexArr.concat(yData);
        });
    
        // Now minDepth and maxDepth should accurately reflect the global min/max of all traces' xData.
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);
    
        // Apply any additional logic or constraints to minValue and maxValue as needed.
        if (minValue < -20) minValue = -20;
    
        // Adjust the limits slightly if needed
        setdepthSelectorTempLims([minValue * 0.995, maxValue * 1.04]);
        setDepthLim([minDepth, maxDepth]);
        
    }, [depthTraces, lockYAxis, currentTrace]);
    


    const onTimeRangeUpdate = useCallback((range) => {
        // console.log("onTimeRangeUpdate trigg", range)
        fetchMinMax.sendRequest({start: range[0].toISOString(), end: range[1].toISOString()})
        
        return () => {
            fetchMinMax.cancel();
            // fetchDepth.cancel
        }
    }, []);

    const onDepthBrush = useCallback((d) => {
        // only update if different
        setDepthLim(existing => { 
            for (let i in existing) {
                if (existing[i] !== d[i]) {
                    return d
                }
            }
            return existing
        })
    }, []);

    useEffect(() => {
        // console.log("TCFetch.data", TCFetch.data)
        TCFetch.data?.length && setSelectedTCs({
            timestamp: selectedTrace,
            data: TCFetch.data[0].filter((d, i) => fetchInfo.data.tcs[i].displayed)
        })
    }, [TCFetch.data, selectedTrace ])

    useEffect(() => {

        TCFetch.sendRequest({dtype: "tc", timestamp: selectedTrace})

        setDepthTraces(ex => {
            // let ts = ex.map(t => Date.parse(t.key));
            // let ts = ex.map(t => t.key);
            let traces = [...ex.filter(t => t.pin)];
            let ts = traces.map(t => t.key);

            if (
                selectedTrace && 
                fetchHighlight.data?.length && 
                // !ts.includes(selectedTrace.valueOf()) &&
                !ts.includes(fetchHighlight.data[0].timestamp)) {
                    let highlight = convertTrace(fetchHighlight.data[0], "red");
                    traces.push(highlight)
            }
            return traces
        })

        return TCFetch.cancel
    }, [currentTrace, selectedTrace, fetchHighlight.data])

    // Take wellAPI.fetchMinMax.data and populate TimeSelectorTraces - original way to set traces based on date select
    // Includes min/max itterations twice
    // useEffect(() => {
    //     if (wellAPI.fetchMinMax.data) {
    //         let minmax = wellAPI.fetchMinMax.data;
        
    //         setTimeSelectorTraces(
    //             [{
    //                 key: "well_min",
    //                 xData: minmax.map(e => e.timestamp),
    //                 yData: minmax.map(e => e.min),
    //                 colour: "blue"
    //             },
    //             {
    //                 key: "well_max",
    //                 xData: minmax.map(e => e.timestamp),
    //                 yData: minmax.map(e => e.max),
    //                 colour: "red"
    //             }]
    //         )
    //     }
    // }, [wellAPI.fetchMinMax.data])

    // new effect to reduce the number of iterations and set traces based on date select - is O(n) instead of O(2n)
    useEffect(() => {
        // Check if data is available before proceeding
        if (wellAPI.fetchMinMax.data) {
            // Destructure minmax for easier access
            const minmax = wellAPI.fetchMinMax.data;
            
            // Pre-allocate arrays based on the length of minmax data
            const xData = new Array(minmax.length);
            const yDataMin = new Array(minmax.length);
            const yDataMax = new Array(minmax.length);
    
            // Iterate once over the minmax data to populate the arrays
            for (let i = 0; i < minmax.length; i++) {
                xData[i] = minmax[i].timestamp; // Assume timestamp is already a Date object
                yDataMin[i] = minmax[i].min;
                yDataMax[i] = minmax[i].max;
            }
    
            // Set the traces with direct references to the newly created arrays
            setTimeSelectorTraces([
                {
                    key: "well_min",
                    xData: xData,
                    yData: yDataMin,
                    colour: "blue"
                },
                {
                    key: "well_max",
                    xData: xData, // Reuse the same xData array for both traces
                    yData: yDataMax,
                    colour: "red"
                }
            ]);
        }
    }, [wellAPI.fetchMinMax.data]);
    

    const timeDrag = (t) => {
        setSelectedTrace(t)
    }

    // Update data for csv download
    useEffect(() => {
        if (!depthTraces.length) return;
        // console.log("updating data for csv download")
        const blob = {}
        blob["Depth"] = depthTraces[0].xData;
        for (let traceIdx = 0; traceIdx<depthTraces.length; traceIdx++){
            blob[(depthTraces[traceIdx].key)] = depthTraces[traceIdx].yData;
        }
        if (!blob) return;
        blob[""] = "";
        blob["TCs @ "+ selectedTrace] = ["Depth", "TCTemp", "FOTemp", "Diff"];
        for (let tcNum=0; tcNum<selectedTCs?.data?.length; tcNum++) {
            let tempTCArray = []
            tempTCArray.push(selectedTCs?.data[tcNum]?.Depth);
            tempTCArray.push(selectedTCs?.data[tcNum]?.TCTemp);
            tempTCArray.push(selectedTCs?.data[tcNum]?.FOTemp);
            tempTCArray.push((selectedTCs?.data[tcNum]?.TCTemp - selectedTCs?.data[tcNum]?.FOTemp).toFixed(2));
            blob["TC_"+selectedTCs?.data[tcNum]?.Depth] = tempTCArray;
        }
        setCSVData(blob);
    }, [ depthTraces, wellAPI ]);
    

    const selectedZone = useMemo(() => {
        if (!wellAPI.fetchZone.data) return;

        return wellAPI.fetchZone.data[0].reduce((p, c, i) => {
            if (selectedTrace && c.getTime() === Date.parse(selectedTrace)) {
                // console.log(wellAPI.fetchZone.data[1].filter(z => fetchInfo.data.zones[i].displayed))
                return {
                    time: c, 
                    zones: wellAPI.fetchZone.data[1].map(z => z[i]).filter((z, i) => fetchInfo.data.zones[i].displayed)
                } 
            }

            return p 
    })
    }, [wellAPI.fetchZone.data, selectedTrace, currentTrace, wellAPI.fetchTCData, fetchInfo.data]);

    useEffect(() => {
        if (!TimeSelectorTraces) return;
        settimeSelectorTempLims([Math.round(Math.min(...TimeSelectorTraces[0].yData)), Math.round(Math.max(...TimeSelectorTraces[1].yData))])
    }, [TimeSelectorTraces])

    //when wellSchema prop is updated in parent component, update schemaSections state
    useEffect(() => {
        setSchemaSections(wellSchema);
    }, [ wellSchema ]);

    // used for calculating the size of the schema bar
    const enabledSectionsCount = schemaSections.filter(sectionWrapper => sectionWrapper.isEnabled).length;
    const totalHeight = enabledSectionsCount * 20 + 10; // Adjusted to account for enabled sections

    //function to zoom in on a particular section when clicked on
    const onSectionClick = (depths) => {
        setDepthLim(depths);
    }

    // Function to handle the POI line toggling
    const handleToggleLines = () => {
        setShowXLines(!showXLines); // This will toggle between true and false
    };

    //Layers are the checkboxes for the well layers - can be selected to show/hide schema bars and plot points of interest
    //Keep in here for now for simplicity - Vlad
    const Layers = ({ schemaSections, toggleLayer}) => {
        return (
            <div>
                {schemaSections.map((sectionWrapper, idx) => (
                    <div key={idx}>
                        <input 
                            type="checkbox" 
                            checked={sectionWrapper.isEnabled} 
                            onChange={() => toggleLayer(idx)}
                        />
                        <label>{sectionWrapper.layerName}</label>
                        <Tooltip title='Plot Points of Interest' >
                            <LineChartOutlined 
                                onClick={() => plotPOI(sectionWrapper.sections, idx, xLines, setXLines, setToggledLayers)} 
                                style={{ fontSize: '18px', 
                                        paddingLeft: '5px', 
                                        color: toggledLayers[idx] ? 'white' : 'black' //change color for clickd layer
                                }}
                                />
                        </Tooltip>
                        <Tooltip title='Plot Sections' >
                            <BgColorsOutlined
                                onClick={() => plotSection(sectionWrapper.sections, idx, xLines, setXLines)}
                                style={{ fontSize: '18px', paddingLeft: '5px'  }} 
                            />
                        </Tooltip>
                    </div>
                ))}
            </div>
        );
    };

    //toggleLayer is used to toggle the schema layers on/off from view
    const toggleLayer = (index) => {
        const updatedSections = [...schemaSections];
        updatedSections[index].isEnabled = !updatedSections[index].isEnabled;
        setSchemaSections(updatedSections);
    };

    return <div style={styles.container}>
                <div style={styles.leftColumn}>
                    <SelectedList 
                        selected={depthTraces} 
                        updateTrace={a => setDepthTraces([...a])} 
                    />
                    <ChartSummary 
                        data={cursorData}
                        xData={xLines}
                        updateXData={a => setXLines([...a])} 
                    />
                </div>
                <div style={styles.middleColumnTop}>
                    <TimeSelector 
                        margins={margins}
                        current={TimeSelectorTraces} 
                        selected={selectedTrace} 
                        setSelectedTrace={setSelectedTrace}
                        yLim={timeSelectorTempLims} 
                        onTimeDrag={timeDrag} 
                        onRangeUpdate={onTimeRangeUpdate}
                        activeTab={activeTab}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        noBrush 
                    />
                </div>
                <div style={styles.chart}>
                    <DepthTempChart
                        margins={margins}
                        traces={depthTraces}
                        xLim={depthLim}
                        yLim={lockYAxis && lockedTempLim ? lockedTempLim : tempLim}
                        tcs={selectedTCs}
                        title={selectedTrace ? `${wellAPI.wellName} - ${adjustToBrowserTime(selectedTrace)}`: "No Data"}
                        setCursorData={setCursorData}
                        setXLines={setXLines}
                        // xLines={xLines}
                        xLines={ xLines }
                        handleWidthUpdate={handleWidthUpdate}
                    />
                </div>
                <div style={{...styles.topRight, marginTop: '40px'}}>
                    <CSVDownload
                        type = "trace"
                        viewer = "TraceView"
                        timestamp={selectedTrace}
                        blob = {csvData}
                        csvDataLoaded={true}
                    />
                    
                    Lock Temperature Axis:
                    < Switch isToggled={lockYAxis} onChange={() => setLockYAxis(!lockYAxis)} />
                
                </div>
                <div style={styles.depthTrack}>
                    <div style={styles.schemaBarContainer}>
                        <SchemaBarContainer //holds however many schema bars are stored in DB
                            schemaSections={schemaSections} 
                            totalHeight={totalHeight}
                            margins={margins}
                            schemaBarWidth={schemaBarWidth}
                            onSectionClick={onSectionClick}
                        />
                    </div>
                    <DepthSelector
                        key={depthSelectorTempLims}
                        margins={margins}
                        current={depthTraces} 
                        onBrush={onDepthBrush} 
                        depthExtents={depthExtents} 
                        xLim={depthLim} 
                        yLim={depthSelectorTempLims} 
                        zoneData={selectedZone}
                        onEditClick={onEditClick}
                    />
                </div>
                <div style={styles.tempTrack}>
                    { TempSelector }
                    <span style={{ fontWeight: 'bold' }}>Well Layers: <EditOutlined onClick={onEditClick} style={{ fontSize: '18px' }}/></span>
                    <Layers schemaSections={schemaSections} toggleLayer={toggleLayer} onToggleLines={handleToggleLines}/>
                </div>
            </div>
}