import { useEffect, useState } from "react";

// Custom hook used to show relevant page info in the document title (AKA browser tab text)
const useDocumentTitle = title => {
  const [documentTitle, setDocumentTitle] = useState(title);
   useEffect(() => {
    document.title = "Petrospec DTS Viz - " + documentTitle; 
  });

  return [documentTitle, setDocumentTitle];
};

export {useDocumentTitle};
