
import { Dropdown, Menu, Avatar } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './index.css';

const HeaderDropdown = props => {
  const { user, menuItems } = props;
  const navigate = useNavigate();

  const onClick = ({ key }) => {
    const item = menuItems.find(i => i.key === key);
    if (item && item.callback) {
      item.callback();
    } else {
      // handle navigation if no callback provided
      navigate(`/${key}`);
    }
  };

  const menu = (
    <Menu onClick={onClick} selectedKeys={[]}>
      {menuItems.map(item => {
        const { key, icon, value } = item;
        return (
          <Menu.Item key={key}>
            {icon}
            <span>{value}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  if (!user)
    return null;

  const { name, picture } = user;

  return (
    <Dropdown overlay={menu} {...props}>
      <div className='header-container'>
        {picture ?
          <Avatar
            size="small"
            src={picture}
            style={{ marginRight: 4 }}
          />
          :
          <Avatar size="small" style={{ marginRight: 4 }} icon={<UserOutlined />} />
        }
        <span>{name}</span>
      </div>
    </Dropdown>
  )
};

export default HeaderDropdown;
