import { useContext } from 'react';
import { UserContext } from '../../api/user';

import { useDocumentTitle } from '../../CustomHooks/useDocumentTitle';
import { Card } from 'antd';
const { Meta } = Card;

const UserProfile = () => {
    const user = useContext(UserContext);

    useDocumentTitle("Profile");
    return <Card>
        <Meta title = {user?.name} description={user?.email}/>
        <br />Admin: { user?.admin ? "Yes" : "No"}<br />
        Pad access:
        { user?.pads.map( p => <li key={p}>{p}</li>)}
    </Card>
}

export default UserProfile;