import ApiRequest from './request';

const normalizeWellName =(well) => {
    return well.includes(' ') ? well : well.toUpperCase()
}

export const TCData = (site, well) =>  {
    well = normalizeWellName(well)

return ApiRequest({
    url: `/dts/site/${site}/${well}/tc`,
    method: 'get',
    transformResponse: (resp) => {
        let data 

        try {
            data = JSON.parse(resp)
        } catch (error) {
            throw Error(`Could not parse response: ${JSON.stringify(error)}`)
        } 

        const tcLength = 10;

        const timestamps = data.timestamps.map(ts => new Date(ts))

        let tcData = new Array(tcLength).fill(null).map(() => [])

        if (!timestamps.length) {
            throw Error(`No timestamps returned: ${data}`)
        }

        for ( let tc of data.data ) {
            for ( let i = 0; i < tcLength; i++ ) {
                tcData[i].push(tc[i])
            }
        }

        return {timestamps, data: tcData}
    }
})
}