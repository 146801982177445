import { useEffect, useContext, useCallback, useMemo } from "react"
import { CanvasContext } from "../Canvas/SVGCanvasOverlay";
import { ChartContext } from "../Canvas/ChartContext";

import * as d3 from "d3";

export const XBrush = ({
    brushExtents,
    onBrush
}) => {
    const {svg } = useContext(CanvasContext);
    const { margins, width, height, xScale} = useContext(ChartContext);

    const chart = d3.select(svg);

    const brushed = useCallback((e) => {
        if (!e.selection || !onBrush) return;
        const newSelect = e.selection.map(xScale.invert)

        // Check if date object, compare times, if not compare values
        if (newSelect[0].getTime && brushExtents[0].getTime) {
            if (
                newSelect[0].getTime() != brushExtents[0].getTime() || 
                newSelect[1].getTime() != brushExtents[1].getTime()) onBrush(newSelect);
        } else {
            if (newSelect[0] != brushExtents[0] || newSelect[1] != brushExtents[1]) onBrush(newSelect);
        }
    }, [onBrush, xScale])

    const brush = useMemo(() => {
        chart.select("g.brushX").remove();

        const brush = d3.brushX()
            .extent([[margins.left + 1, margins.top], [width - margins.right, height - margins.bottom - 1]])
            .on("end", brushed);        

        chart.append("g")
              .attr("class", "brushX")
              .call(brush)

        return brush
    }, [margins, height, width, brushed]);

    useEffect(() => {
        if (!brushExtents || !xScale) return;

        chart.select("g.brushX")
            .call(brush.move, brushExtents.map(xScale));

    }, [brush, brushExtents, xScale])

    return null
}

export const YBrush = ({
    brushExtents,
    onBrush
}) => {
    const {svg } = useContext(CanvasContext);
    const { margins, width, height, yScale} = useContext(ChartContext);

    const chart = d3.select(svg);

    const brushed = useCallback((e) => {
        if (!e.selection || !onBrush) return;
        const newSelect = e.selection.map(yScale.invert).reverse().map(Math.round)
        onBrush(newSelect)
        //if (newSelect[0] != brushExtents[0] || newSelect[1] != brushExtents[1]) onBrush(newSelect);
    }, [onBrush, yScale])

    const brush = useMemo(() => {
        d3.selectAll("g.brushY").remove();

        const brush = d3.brushY()
            .extent([[margins.left + 1, margins.top], [width - margins.right, height - margins.bottom]])
            .on("end", brushed);        

        chart.append("g")
              .attr("class", "brushY")
              .call(brush)
        
        return brush
    }, [margins, height, width, brushed]);

    useEffect(() => {
        if (!brushExtents || !yScale) return;
        chart.select("g.brushY")
                .call(brush.move, brushExtents.slice().reverse().map(yScale));
    }, [brush, brushExtents, yScale])

    return null
}