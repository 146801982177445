//Heloper Function to check if the schema data entered by the user is valid or not

export const isValidInput = (layerName, sectionType, depth, label, minDepth, maxDepth, start, end) => {
    if (layerName === "Select Layer Name") {
        alert("Please select a layer name!");
        return false;
    }

    if (sectionType === "Point of Interest") {
        if (depth.trim() === "") {
            alert("Depth cannot be empty for Point of Interest.");
            return false;
        }
        if (parseFloat(depth) < minDepth || parseFloat(depth) > maxDepth) {
            alert(`Depth must be within the valid range (${minDepth}-${maxDepth}) for Point of Interest.`);
            return false;
        }
        if (label.trim() === "") {
            alert("Label cannot be empty for Point of Interest.");
            return false;
        }
        if (label.length > 20) {
            alert("Label length must be less than 20 characters for Point of Interest.");
            return false;
        }
    } else {
        const floatStart = parseFloat(start);
        const floatEnd = parseFloat(end);
        if (start.trim() === "") {
            alert("Start depth cannot be empty for Section.");
            return false;
        }
        if (end.trim() === "") {
            alert("End depth cannot be empty for Section.");
            return false;
        }
        if (floatStart < minDepth || floatEnd > maxDepth) {
            alert(`Depths must be within the valid range (${minDepth}-${maxDepth}) for Section.`);
            return false;
        }
        if (floatStart >= floatEnd) {
            alert("Start depth must be less than end depth for Section.");
            return false;
        }
        if (label.trim() === "") {
            alert("Label cannot be empty for Section.");
            return false;
        }
        if (label.length > 20) {
            alert("Label length must be less than 20 characters for Section.");
            return false;
        }
    }

    return true;
};
