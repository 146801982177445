import { useState, useEffect, useCallback, useContext, useMemo } from "react";

import { useTempSelector, useCursorData } from "../Hooks";
import { WellContext } from "../../../api/well";

import { ChartWithOverlay } from "../ChartWithOverlay";
import { SelectedList } from "../Selectors/SelectedList";
import { TimeSelector } from "../Selectors";
import { ChartSummary } from "../Tooltip/Summary";

import { DepthSelector } from "../Selectors";

import dayjs from "dayjs";

import convertTrace from "../convert";
import { depthGen } from "../../../utils/depth";

import "./layout.css";
import { shortDate } from "../../../utils/date";

function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

const colours = [
    "#005f73",
    "#0a9396",
    "#94d2bd",
    "#e9d846",
    "#ee9b00",
    "#ca6702",
    "#bb3e03",
    "#ae2012",
    "#9b2226",
];

const styles = {
    container: {
        display: "grid",
        gridTemplateColumns: "300px 1fr 150px",
        gridTemplateRows: "150px 1fr 1fr 200px",
        height: "90vh",
    },
    leftColumn: {
        gridColumn: "1 / 2",
        gridRow: "1 / 5",
        backgroundColor: "#e6e6e6",
    },
    middleColumnTop: {
        gridColumn: "2 / 3",
        gridRow: "1 / 2",
    },
    chart: {
        gridColumn: "2 / 3",
        gridRow: "2 / 3",
        backgroundColor: "grey",
    },
    depthChart: {
        gridColumn: "2 / 3",
        gridRow: "3 / 4",
        backgroundColor: "grey",
    },
    depthTrack: {
        gridColumn: "2 / 3",
        gridRow: "4 / 5",
        backgroundColor: "lightgrey",
    },
    tempTrack: {
        gridColumn: "3 / 4",
        gridRow: "2 / 4",
        backgroundColor: "lightgrey",
    },
};

const margins = {
    top: 20,
    bottom: 30,
    left: 60,
    right: 55,
};

export const ZoneVTimeLayout = ({}) => {
    const wellAPI = useContext(WellContext);
    const { fetchInfo, fetchTrace, fetchZone, fetchLatest, queryTimestamps } =
        wellAPI;

    const fetch = fetchTrace.copy();

    const [dateLim, setDateLim] = useState();

    const [traces, setTraces] = useState([]);

    const [TimeSelectorTraces, setTimeSelectorTraces] = useState([]);

    const [xLines, setXLines] = useState([]);

    const [tempLim, setTempLim, TempSelector] = useTempSelector(
        traces,
        margins
    );

    const [cursorData, setCursorData] = useCursorData(traces);

    const [selectedTrace, setSelectedTrace] = useState();

    const [depthLim, setDepthLim] = useState();

    const [depthExtents, setDepthExtents] = useState([0, 1]);

    const [depthTraces, setDepthTraces] = useState([]);

    const [zoneLines, setZoneLines] = useState([]);

    const [depthSelectorTempLims, setdepthSelectorTempLims] = useState();

    const [timeSelectorTempLims, settimeSelectorTempLims] = useState();

    const [TempVTimeTempLims, setTempVTimeTempLims] = useState();

    const [wasInitialXLineSet, setwasInitialXLineSet] = useState(false);

    const [prevFetchData, setPrevFetchData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (queryTimestamps.data) {
            fetchZone.sendRequest({
                start: queryTimestamps.data[0],
                end: queryTimestamps.data[queryTimestamps.data.length - 1],
            });
            return () => {
                fetchZone.cancel();
            };
        }
    }, [queryTimestamps.data]);

    useEffect(() => {
        if (fetchZone.data && fetchLatest.data) {
            if (!wasInitialXLineSet && traces.length > 0) {
                setXLines(
                    [
                        {
                            key: new Date(fetchLatest.data.timestamp),
                            colour: "red",
                            data: traces.map(t => (
                                {
                                    key: t.key,
                                    colour: t.colour,
                                    visible: t.visible,
                                    width: 3,
                                    x: shortDate(new Date(fetchLatest.data.timestamp)),
                                    xLabel: shortDate(new Date(fetchLatest.data.timestamp)),
                                    xVal: new Date(fetchLatest.data.timestamp),
                                    y: t.yData[t.yData.length - 1],
                                    yLabel: `${Math.round(t.yData[t.yData.length - 1])} ${"\u00B0" + "C"}`,
                                    yVal: t.yData[t.yData.length - 1]
                                }
                            )),
                            width: 3,
                            x: new Date(fetchLatest.data.timestamp),
                            xVal: new Date(fetchLatest.data.timestamp),
                            xLabel: shortDate(new Date(fetchLatest.data.timestamp)),
                            visible: true,
                        }
                    ]
                )
                setwasInitialXLineSet(true)
            }
        }

    }, [fetchZone.data, fetchLatest.data, traces]);

    useEffect(() => {
        if (fetch.data && JSON.stringify(fetch.data) !== JSON.stringify(prevFetchData)) {
            const newDepthTraces = fetch.data.reduce((acc, current) => {
                const existingTrace = depthTraces.find(
                    (depthTrace) => depthTrace.key === current.timestamp
                );
    
                if (existingTrace) {
                    acc.push(existingTrace);
                } else {
                    const newTrace = convertTrace(current);
                    newTrace.colour = `rgba(254, ${
                        200 - (200 * (acc.length + 1)) / fetch.data.length
                    }, 0)`;
    
                    acc.push(newTrace);
                }
    
                return acc;
            }, []);
    
            setDepthTraces(newDepthTraces);
            setPrevFetchData(fetch.data);
        }
    }, [fetch.data, depthTraces]);
    

    useEffect(() => {
        if (xLines.length == 0) return;
        fetch.sendRequest({
            dtype: "temp",
            timestamp: queryTimestamps.data[queryTimestamps.data.length - 1],
        });
        return fetch.cancel;
    }, []);

    useEffect(() => {
        if (xLines?.length == 0) return;
        // let filter = depthTraces.filter(t => !xLines.map(x =>  formatDate(x.key)).includes(formatDate(t.key)))
        // console.log({"filter":filter})
        fetch.sendRequest({
            dtype: "temp",
            timestamp: xLines.map((x) => x.x),
        });
        return fetch.cancel;
    }, [xLines, depthTraces]);
    
// Helper function for deep equality check
function deepEqual(a, b) {
    if (a === b) {
      return true;
    }
    if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
      return false;
    }
    let keysA = Object.keys(a), keysB = Object.keys(b);
    if (keysA.length !== keysB.length) {
      return false;
    }
    for (let key of keysA) {
      if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  
  useEffect(() => {
      if (!xLines || xLines.length === 0) return;
      else if (fetch.data) {
          if (Array.isArray(xLines)) {
              // Create a deep copy of depthTraces
              let prevDepthTraces = depthTraces.map(obj => ({...obj}));
  
              xLines.forEach(newData => {
                  // Format the key of xLines data
                  const formattedXLineKey = formatDate(newData.key);
                  // Find matching depthTrace
                  const matchingDepthTrace = prevDepthTraces.find(depthTrace => formatDate(depthTrace.key) === formattedXLineKey);
                  // If a match is found
                  if (matchingDepthTrace) {
                      // Update matchingDepthTrace with the properties from xLines
                      matchingDepthTrace.colour = newData.colour;
                      matchingDepthTrace.visible = newData.visible === undefined ? true : newData.visible;
                      matchingDepthTrace.pin = newData.pin === undefined ? false : newData.pin;
                  }
              });
  
              // Only update the depthTraces state if there has been a change
              if (!deepEqual(prevDepthTraces, depthTraces)) {
                  setDepthTraces(prevDepthTraces);
              }
          }
      }
  
  }, [xLines]);
  
    function formatDate(dateInput) {
        let date;
    
        // Check if dateInput is a Date object.
        if (dateInput instanceof Date) {
            date = dateInput;
        } 
        // Check if dateInput is a number (like a timestamp).
        else if (typeof dateInput === 'number') {
            date = new Date(dateInput);
        } 
        // Try parsing dateInput as a string.
        else if (typeof dateInput === 'string') {
            date = new Date(dateInput);
        }
    
        // If date is a Date object and is a valid date.
        if (date instanceof Date && !isNaN(date.getTime())) {
            // Return date as an ISO string.
            return date.toISOString();
        } else {
            return null;
        }
    }

    useEffect(() => {
        const z = fetchInfo.data?.zones.filter((z) => z.displayed);
        if (!z || !fetchZone.data) return;

        const alldepths = z.map((zone) => [zone.b, zone.e]).flat();

        setDepthLim([Math.min(...alldepths), Math.max(...alldepths)]);

        const [timestamps, data] = fetchZone.data;

        setZoneLines(
            z.map((zone, i) => ({
                key: zone.name,
                x: zone.b,
                width: zone.e - zone.b,
                colour: hexToRGB(colours[i], 0.2),
            }))
        );

        /*
        {
          "key": "2023/02/17 09:57",
          "x": 166.5,
          "y": 173.8333282470703,
          "xVal": "2023-02-17T16:57:09.000Z",
          data: []
        */

        /*
        key: trace.timestamp,
        yData: trace.data,
        xData: depthGen({
            start: trace.start,
            step:  trace.step,
            span:  trace.span,
        }),
        colour: colour || colourWheel(),
        pin: false,
        visible: true
        */

        setTraces(
            z.reduce((p, c, i) => {
                if (c.displayed) {
                    p.push({
                        key: c.name,
                        xData: timestamps,
                        yData: data[i].map((p) => p.mx),
                        colour: colours[i],
                        pin: false,
                        visible: true,
                    });
                }

                return p;
            }, [])
        );

        /* z
        [
        {
          "displayed": true,
          "b": 745,
          "e": 840,
          "name": "z0",
          "mx": 188.95000000000002,
          "mn": 183.66
        }, ...]
        */

        var minDepth, maxDepth;

        for (let zone of z) {
            if (zone.b < minDepth || !minDepth) minDepth = zone.b;
            if (zone.e > maxDepth || !maxDepth) maxDepth = zone.e;
        }

        setDepthExtents([minDepth, maxDepth]);
    }, [fetchZone.data, fetchInfo.data]);

    useEffect(() => {
        if (queryTimestamps.data) {
            setDateLim([
                dayjs(queryTimestamps.data[0]),
                dayjs(queryTimestamps.data[queryTimestamps.data.length - 1]),
            ]);
        }
    }, [queryTimestamps.data]);

    // Just grab the last zone point and check if it's displayed, if so, add it to the list
    const selectedZone = useMemo(() => {
        if (!fetchZone.data || !fetchInfo.data?.zones) return;
        const z = fetchZone.data[1]
            .map((zone) => zone[zone.length - 1])
            .filter((z, i) => fetchInfo.data.zones[i].displayed);
        return {
            time: fetchZone.data[0][fetchZone.data[0].length - 1],
            zones: z,
        };
    }, [fetchZone.data]);

    // Zone will have a temp brush, time brush and depth brush..

    const onTimeBrush = useCallback((selection) => {
        const closestDates =
            traces.length == 0
                ? selection
                : findClosestDates(traces[0]?.xData, selection);
        setDateLim(closestDates);
    }, []);

    const onDepthBrush = useCallback((d) => {
        // only update if different
        setDepthLim((existing) => {
            for (let i in existing) {
                if (existing[i] != d[i]) {
                    return d;
                }
            }
            return existing;
        });
    }, []);

    const findClosestDates = (datesArray, startAndEndDates) => {
        const [startDate, endDate] = startAndEndDates;
        let closestStartDate, closestEndDate;
        let minStartDifference = Infinity;
        let minEndDifference = Infinity;
        const startDateMillis = new Date(startDate).getTime();
        const endDateMillis = new Date(endDate).getTime();

        datesArray.forEach((date) => {
            const dateMillis = new Date(date).getTime();
            const startDifference = Math.abs(dateMillis - startDateMillis);
            const endDifference = Math.abs(dateMillis - endDateMillis);

            if (startDifference < minStartDifference) {
                minStartDifference = startDifference;
                closestStartDate = date;
            }

            if (endDifference <= minEndDifference) {
                minEndDifference = endDifference;
                closestEndDate = date;
            }
        });

        return [closestStartDate, closestEndDate];
    };

    // Set the timeSelectorTempLims to to always always have all traces fully in view within depth section
    useEffect(() => {
        if (!traces) return;
        if (traces?.length == 0) return;
        if (!depthLim) return;
        let yIndexArr = [];
        for (let i = 0; i < traces?.length; i++) {
            let xDataArr = traces[i]["xData"];
            let yDataArr = traces[i]["yData"];
            let closestDates = findClosestDates(xDataArr, dateLim);
            let startDate = new Date(closestDates[0]);
            let endDate = new Date(closestDates[1]);
            let startIndex = xDataArr.findIndex(
                (date) => date.getTime() == startDate.getTime()
            );

            let endIndex = xDataArr.findIndex(
                (date) => new Date(date).getTime() == endDate.getTime()
            );
            let tempXIndexArr = xDataArr.slice(startIndex, endIndex + 1);
            let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
            yIndexArr = yIndexArr.concat(tempYIndexArr);
        }
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);

        if (minValue < -20) minValue = -20;

        // console.log(`Minimum value: ${minValue}`);
        // console.log(`Maximum value: ${maxValue}`);

        if (!timeSelectorTempLims) {
            settimeSelectorTempLims([minValue * 0.995, maxValue * 1.005]);
        }
        setTempVTimeTempLims([minValue * 0.995, maxValue * 1.005]);
    }, [queryTimestamps, traces, dateLim]);

    // Set the tempLims to to always always have all traces fully in view within depth section
    useEffect(() => {
        if (!depthTraces) return;
        if (depthTraces?.length == 0) return;
        if (!depthLim) return;
        if (traces.length == 0) return;
        let yIndexArr = [];
        for (let i = 0; i < depthTraces?.length; i++) {
            let xDataArr = depthTraces[i]["xData"];
            let yDataArr = depthTraces[i]["yData"];
            let startIndex = xDataArr.indexOf(Math.round(depthLim[0]));
            let endIndex =
                xDataArr.indexOf(Math.round(depthLim[1])) == -1
                    ? depthTraces[0]?.xData?.length - 1
                    : xDataArr.indexOf(Math.round(depthLim[1]));
            let tempXIndexArr = xDataArr.slice(startIndex, endIndex + 1);
            let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
            yIndexArr = yIndexArr.concat(tempYIndexArr);
        }
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);

        if (minValue < -20) minValue = -20;

        // console.log(`Minimum value: ${minValue * 0.995}`);
        // console.log(`Maximum value: ${maxValue * 1.005}`);

        setTempLim([minValue * 0.995, maxValue * 1.005]);
    }, [queryTimestamps, depthTraces, depthLim]);

    // Set the depthSelectorTempLims to to always have all traces in view through the entire length of the string
    useEffect(() => {
        if (!depthTraces) return;
        if (depthTraces?.length == 0) return;
        if (!depthLim) return;
        if (!zoneLines || !zoneLines.length) return;

        let initialDepthLim = [
            zoneLines[0]?.x,
            zoneLines.slice(-1)[0].x + zoneLines.slice(-1)[0].width,
        ];
        let yIndexArr = [];
        for (let i = 0; i < depthTraces?.length; i++) {
            let xDataArr = depthTraces[i]["xData"];
            let yDataArr = depthTraces[i]["yData"];
            let startIndex = xDataArr.indexOf(Math.round(initialDepthLim[0]));
            let endIndex =
                xDataArr.indexOf(Math.round(initialDepthLim[1])) == -1
                    ? xDataArr.length - 1
                    : xDataArr.indexOf(Math.round(initialDepthLim[1]));
            let tempXIndexArr = xDataArr.slice(startIndex, endIndex + 1);
            let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
            yIndexArr = yIndexArr.concat(tempYIndexArr);
        }

        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);

        if (minValue < -20) minValue = -20;

        // console.log(`Minimum value: ${minValue}`);
        // console.log(`Maximum value: ${maxValue}`);

        setdepthSelectorTempLims([minValue * 0.995, maxValue * 1.005]);
    }, [depthTraces, zoneLines]);

    const tempExtents = useMemo(() => {
        const tempLims = timeSelectorTempLims
        ? [
              Math.round(timeSelectorTempLims[0]),
              Math.round(timeSelectorTempLims[1]),
          ]
        : null
        return tempLims
    },[timeSelectorTempLims])

    return (
        <div style={styles.container}>
            <div style={styles.leftColumn}>
                <SelectedList
                    selected={traces}
                    updateTrace={(a) => setTraces([...a])}
                />
                <ChartSummary
                    data={cursorData}
                    xData={xLines}
                    updateXData={(a) => setXLines([...a])}
                />
            </div>
            <div style={styles.middleColumnTop}>
                <TimeSelector
                    current={traces}
                    selected={selectedTrace}
                    yLim={tempExtents}
                    onBrush={onTimeBrush}
                    showDatePicker={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    // current={TimeSelectorTraces}
                    // onTimeDrag={timeDrag}
                />
            </div>
            <div style={styles.chart}>
                <ChartWithOverlay
                    margins={margins}
                    xScaleType="time"
                    xLim={dateLim}
                    yLim={TempVTimeTempLims}
                    title="Zone Temperatures"
                    traces={traces}
                    yUnit="℃"
                    setCursorData={setCursorData}
                    // onDoubleClick={setXLines}
                    xLines={xLines}
                    setXLines={setXLines}
                    useCrosshairs={true}
                />
            </div>
            <div style={styles.depthChart}>
                <ChartWithOverlay
                    xLim={depthLim}
                    yLim={tempLim}
                    title="Selected Depth Profiles"
                    traces={depthTraces}
                    yUnit="℃"
                    xUnit="mKB"
                    xLines={zoneLines}
                    //    setCursorData={setCursorData}
                    //    onDoubleClick={setXLines}
                />
            </div>

            <div style={styles.topRight}>
                {/* <CSVDownload
                type = "trace"
                timestamp={currentTrace?.timestamp}
                blob = {csvData}
            /> */}
            </div>
            <div style={styles.depthTrack}>
                {/* <DepthSelector
                margins={margins}
                current={depthTraces} 
                onBrush={onDepthBrush} 
                xLim={depthLim}
                yLim={depthSelectorTempLims}
                zoneData={selectedZone}
                depthExtents={depthExtents}
            /> */}
                <DepthSelector
                    key={depthSelectorTempLims}
                    margins={margins}
                    current={depthTraces}
                    onBrush={onDepthBrush}
                    depthExtents={depthExtents}
                    xLim={depthLim}
                    yLim={depthSelectorTempLims}
                    zoneData={selectedZone}
                />
            </div>
            <div style={styles.tempTrack}>{TempSelector}</div>
        </div>
    );
};

// const ZoneVTimeLayoutOld = ({}) => {
//     const wellAPI = useContext(WellContext);
//     const { fetchInfo, fetchTrace, fetchZone } = wellAPI;

//     const [dateLim, setDateLim] = useState();

//     const [traces, setTraces] = useState([]);

//     const [TimeSelectorTraces, setTimeSelectorTraces] = useState([]);

//     const [xLines, setXLines] = useState([]);

//     const [tempLim, setTempLim, TempSelector] = useTempSelector(
//         traces,
//         [0, 300],
//         1
//     );

//     const [cursorData, setCursorData] = useCursorData(traces);

//     const [selectedTrace, setSelectedTrace] = useState();

//     const [depthLim, setDepthLim] = useState();

//     const [depthTraces, setDepthTraces] = useState([]);

//     const [zoneLines, setZoneLines] = useState([]);

//     /*
//     const [ range, setRange ] = useState(defaultRange);

//     const [ depthTraces, setDepthTraces ] = useState([]);
//     const [ depthLim, setDepthLim ] = useState();
//     const [ depthExtents, setDepthExtents ] = useState([0, 1]);

//     const [ currentTrace, setCurrentTrace ] = useState();

//     const [ selectedTrace, setSelectedTrace ] = useState(wellAPI?.fetchTrace?.currentRequest?.timestamp)
//     const [ selectedTCs, setSelectedTCs ] = useState();

//     const tcs = wellAPI?.fetchTCData?.data;
//     */

//     // useEffect to update the traces
//     // console.log("xLines", xLines)

//     const fetchDepthTraces = fetchTrace.copy();

//     useEffect(() => {
//         fetchDepthTraces.sendRequest({
//             dtype: "temp",
//             timestamp: xLines.map((x) => x.xVal),
//         });
//         return fetchDepthTraces.cancel;
//     }, [xLines]);

//     useEffect(() => {
//         // setDepthTraces(fetchDepthTraces.data?.map(convertTrace))
//     }, [fetchDepthTraces.data]);

//     // setTempLim to min and max of the current trace if it's not already set
//     useEffect(() => {
//         if (!traces.length) return;

//         const min =
//             Math.floor(
//                 Math.min(...traces.map((t) => Math.min(...t.yData))) / 5
//             ) * 5;
//         const max =
//             Math.ceil(
//                 Math.max(...traces.map((t) => Math.max(...t.yData))) / 5
//             ) * 5;

//         if (!tempLim) setTempLim([min, max]);

//         setTimeSelectorTraces(traces);
//         // console.log("TRACES", traces)
//     }, [traces, tempLim]);

//     // useEffect(() => {
//     //     if (!wellAPI.fetchZone.data) return;

//     //     const [timestamps, data]  = wellAPI.fetchZone.data;

//     //     // for (let i=0; i < data.length; i++) {
//     //     //     const z = data[i];
//     //     //     const zName = wellAPI.fetchInfo.data.zones[i].name;

//     //     //     const trace = {
//     //     //         key: zName,
//     //     //         xData: timestamps,
//     //     //         yData: z.map(p => p.mx),
//     //     //         colour: "red"
//     //     //     }
//     //     //     setTraces(e => [...e, trace])
//     //     // }

//     //     // setTraces(data.map((z, i) => {
//     //     //     const zName = wellAPI.fetchInfo.data.zones[i].name;

//     //     //     return {
//     //     //         key: zName,
//     //     //         xData: timestamps,
//     //     //         yData: z.map(p => p.mx),
//     //     //         colour: "red"
//     //     //     }
//     //     // }))
//     // }, [wellAPI.fetchZone.data])

//     //if event type is brush, set the dateLim to the the xScale.invert of the selection
//     // const onTimeBrush = useCallback(({type, selection}) => {

//     const onTimeBrush = useCallback((selection) => {
//         setDateLim(selection);
//         /*
//         if (type == "brush") {
//             setDateLim(selection)
//         }
//         */
//     }, []);

//     const selectedZone = useMemo(() => {
//         if (!wellAPI.fetchZone.data) return;

//         return wellAPI.fetchZone.data[0].reduce((p, c, i) => {
//             /*
//         if (JSON.stringify(c) == JSON.stringify(selectedTrace)){
//             let tcObject = {};
//             let tcTemps = [];
//             for (let tcIdx=wellAPI?.fetchTCData?.data?.data.length-1; tcIdx >= 0; tcIdx--){
//                 tcTemps.push(wellAPI?.fetchTCData?.data?.data[tcIdx][i]);
//                 tcObject["data"]=tcTemps;
//                 tcObject["timestamp"]=wellAPI?.fetchTCData?.data?.timestamps[i];
//             }
//             setSelectedTCs(tcObject);
//         }
//         */

//             /*
//         if (selectedTrace && c.getTime() == selectedTrace.getTime()) {
//             return {time: c, zones: wellAPI.fetchZone.data[1].map(z => z[i])}
//         }
//         */
//             return p;
//         });
//         // }, [wellAPI.fetchZone.data, selectedTrace, currentTrace]);
//     }, [wellAPI.fetchZone.data]);

//     /*
//     const cursorUpdate = data => {
//         if (!data) return;

//         // groupby x
//         const reduced = data.reduce((acc, cur) => {
//             (acc[cur.x] = acc[cur.x] || []).push(
//                 {
//                     key: cur.key,
//                     yLabel: cur.y,
//                     colour: cur.colour,
//                     visible: cur.visible
//                 })
//             return acc
//         }, {})

//         const arr = Object.keys(reduced).map(k => ({
//             key: k,
//             data: reduced[k]
//         }))

//         setCursorData(arr)
//     }
//     */

//     const onDoubleClick = useCallback(
//         (e) => {
//             wellAPI.fetchTrace.sendRequest({ timestamp: e, dtype: "temp" });
//         },
//         [wellAPI]
//     );

//     useEffect(() => {
//         if (!wellAPI.fetchTrace.data?.length) return;

//         const newTrace = wellAPI.fetchTrace.data[0];

//         setDepthTraces((existing) => {
//             const i = existing.length + 1;

//             return [
//                 ...existing,
//                 {
//                     key: newTrace.timestamp,
//                     xData: depthGen(newTrace),
//                     yData: newTrace.data,
//                     colour: colours[i],
//                 },
//             ];
//         });
//     }, [wellAPI.fetchTrace.data]);

//     return (
//         <div style={styles.container}>
//             <div style={styles.leftColumn}>
//                 <SelectedList
//                     selected={traces}
//                     updateTrace={(a) => setTraces([...a])}
//                 />
//                 <ChartSummary
//                     data={cursorData}
//                     xData={xLines}
//                     updateX={(a) => setXLines([...a])}
//                 />
//             </div>
//             <div style={styles.middleColumnTop}>
//                 <TimeSelector
//                     current={TimeSelectorTraces}
//                     selected={selectedTrace}
//                     yLim={tempLim}
//                     // onTimeDrag={timeDrag}
//                     noBrush
//                 />
//             </div>
//             <div style={styles.chart}>
//                 <ChartWithOverlay
//                     xScaleType="time"
//                     xLim={dateLim}
//                     yLim={tempLim}
//                     title="Zone Temperatures"
//                     traces={traces}
//                     yUnit="℃"
//                     setCursorData={setCursorData}
//                     onDoubleClick={setXLines}
//                 />
//             </div>
//             <div style={styles.depthChart}>
//                 <ChartWithOverlay
//                     xLim={depthLim}
//                     yLim={tempLim}
//                     title="Selected Depth Profiles"
//                     traces={depthTraces}
//                     yUnit="℃"
//                     xUnit="mKB"
//                     xLines={zoneLines}
//                     //    setCursorData={setCursorData}
//                     //    onDoubleClick={setXLines}
//                 />
//             </div>
//             <div style={styles.topRight}>
//                 {/* <CSVDownload
//                 type = "trace"
//                 timestamp={currentTrace?.timestamp}
//                 blob = {csvData}
//             /> */}
//             </div>
//             <div style={styles.depthTrack}>
//                 {/* <DepthSelector
//                 key={depthSelectorTempLims}
//                 margins={margins}
//                 current={depthTraces}
//                 onBrush={onDepthBrush}
//                 depthExtents={depthExtents}
//                 xLim={depthLim}
//                 yLim={depthSelectorTempLims}
//                 zoneData={selectedZone}
//             /> */}
//             </div>
//             <div style={styles.tempTrack}>{TempSelector}</div>
//         </div>
//     );

//     return (
//         <>
//             <div id="TvDLayout">
//                 <div id="TvDTopRow" className="TvDrow">
//                     <div id="topBar">
//                         <TimeSelector
//                             yLim={tempLim}
//                             current={TimeSelectorTraces}
//                             onBrush={onTimeBrush}
//                         />
//                     </div>
//                 </div>
//                 <div
//                     id="TvDChartRow"
//                     className="TvDrow"
//                     style={{ height: "50vh" }}
//                 >
//                     <div id="leftBar" className="leftBar">
//                         <ChartSummary
//                             data={cursorData}
//                             depthData={xLines}
//                             updateDepths={(a) => setXLines([...a])}
//                         />
//                     </div>
//                     <div className="chartContent"></div>
//                 </div>
//                 {/* <div id="TvDChartRow" className="TvDrow" style={{height: "50vh"}}>
//                 <div className="chartContent">
//                         <ChartWithOverlay
//                             xLim={[0, 2000]}
//                             yLim={tempLim}
//                             title="Temperature vs Depth"
//                             traces={depthTraces}
//                             xUnit="m"
//                             yUnit="℃"
//                             // tcs={tcs}
//                         />
//                 </div>
//             </div> */}
//             </div>
//         </>
//     );

//     /*
//     // Update depthLimits and depthExtents
//     useEffect(() => {
//         if (currentTrace) {
//             let newDl = depthLimits(currentTrace)
//             newDl[0] = 0;

//             setDepthExtents(existing => {
//                 for (let i in existing) {
//                     if (existing[i] != newDl[i]) return newDl
//                 }
//                 return existing
//             })

//             if (!depthLim) {
//                 // console.log({newDl})
//                 setDepthLim(newDl)
//             }

//             // setTraces(e => [...e, currentTrace])
//             setTraces(e => [currentTrace])
//         }
//     }, [currentTrace, selectedTrace])

//     useEffect(() => {
//         wellAPI.fetchZone.sendRequest({range})
//         return wellAPI.fetchZone.cancel
//     }, [range])

//     useEffect(() => {
//         wellAPI.queryTimestamps.sendRequest({range})
//         return wellAPI.queryTimestamps.cancel
//     }, [range]);

//     useEffect(() => {
//         wellAPI.fetchMinMax.sendRequest({range})
//         return wellAPI.fetchMinMax.cancel
//     }, [range])

//     useEffect(() => {
//         wellAPI.fetchInfo.sendRequest()
//         return wellAPI.fetchInfo.cancel
//     }, [range])

//     useEffect(() => {
//         selectedTrace && wellAPI.fetchTrace.sendRequest({timestamp: selectedTrace, dtype: "temp"})
//     }, [selectedTrace])

//     useEffect(() => {
//         wellAPI.fetchTrace.data && setCurrentTrace(wellAPI.fetchTrace.data[0])
//     }, [wellAPI.fetchTrace.data])

//     useEffect(() => {
//         wellAPI.fetchLatest.sendRequest().then(() =>
//             wellAPI.fetchLatest.data && setCurrentTrace(wellAPI.fetchLatest.data)
//         );
//         return wellAPI.fetchLatest.cancel
//     }, [])

//     useEffect(() => {
//         if (!depthLim && currentTrace) {
//           setDepthLim([0, currentTrace.span + currentTrace.start])
//         }

//         if (!tempLim && currentTrace) {
//           setTempLim([0, 300])
//         }
//       }, [currentTrace])

//     const onTempBrush = useCallback((t) => {
//         // only update if different
//         setTempLim(existing => {
//             for (let i in existing) {
//                 if (existing[i] != t[i]) {
//                     return t
//                 }
//             }
//             return existing
//         })
//     }, []);

//     const onDepthBrush = useCallback((d) => {
//         // only update if different
//         setDepthLim(existing => {
//             for (let i in existing) {
//                 if (existing[i] != d[i]) {
//                     return d
//                 }
//             }
//             return existing
//         })
//     }, []);

//     useEffect(() => {
//         */
//     /*
//         Still using the DTSRecord from api, all other traces that are requested are of the simpler form
//         based on dtype style, so try to convert to something that works

//         The dead give-away is the captitalized items in the json object
//         */

//     /*
//         Populate the traces with appropriate data
//         */
//     /*
//         setDepthTraces(existing => {
//             if (!existing.map(e => e.timestamp).includes(selectedTrace)) {
//                 const converted = {
//                     key: currentTrace?.timestamp,
//                     yData: currentTrace?.data,
//                     xData: depthGen({
//                     start: currentTrace?.start,
//                     step: currentTrace?.step,
//                     span: currentTrace?.span,
//                     }),
//                     colour: "#a87532"
//                 }

//             return [ converted ]
//             }
//         })
//     }, [currentTrace, selectedTrace])

//     // useEffect(() => {
//     //     /*
//     //     Populate TCs, stripping out unused ones
//     //     */

//     //     const tcMap = wellAPI.fetchInfo.data?.tcs;

//     //     if (tcMap && currentTrace) {
//     //       let arr = [];

//     //       for (let i in tcMap) {
//     //         const tc = tcMap[i];

//     //         if (tc.displayed) {
//     //           arr.push(tcMap[i])
//     //         }
//     //       }

//     //       setSelectedTCs(existing => {
//     //         if (existing?.key != currentTrace.timestamp) {
//     //           return {
//     //             key: currentTrace,
//     //             data: arr
//     //           }
//     //         }

//     //         return existing
//     //       })

//     //     }
//     // return <>
//     //     <TraceSelector traceSelected={(t) => setSelectedTrace(t)}/>
//     //     <p>tempvdepth</p>
//     // console.log("CHARTING PROPS: ", [{margins}, {depthTraces}, {depthLim}, {tempLim}, {tcs}])
//     // return (depthTraces[0]?.key!=undefined) ?
//     //         <>
//     //             <>
//     //                 <TraceSelector traceSelected={(t) => setSelectedTrace(t)}/>
//     //             </>
//     //             <>
//     //                 <WellContext.Provider value={wellAPI}>
//     //                     {/* <TraceSelector traceSelected={(t) => setSelectedTrace(t)}/> */}
//     //                     <div className="latestChart">
//     //                         <DepthTempChart
//     //                             margins={margins}
//     //                             traces={depthTraces}
//     //                             xLim={depthLim}
//     //                             yLim={tempLim}
//     //                             tcs={tcs}
//     //                             // title={`${wellAPI.wellName} - ${selectedTrace}`}
//     //                         />
//     //                     </div>
//     //                 </WellContext.Provider>
//     //             </>
//     //         </>
//     //         :
//     //         <>
//     //             <TraceSelector traceSelected={(t) => setSelectedTrace(t)}/>
//     //         </>

//     /*
//     const selectedZone = useMemo(() => {
//         if (!wellAPI.fetchZone.data) return;

//         return wellAPI.fetchZone.data[0].reduce((p, c, i) => {
//         if (JSON.stringify(c) == JSON.stringify(selectedTrace)){
//             // console.log("Found the trace at index "+i)
//             // console.log({c}, {selectedTrace})
//             let tcObject = {};
//             let tcTemps = [];
//             for (let tcIdx=wellAPI?.fetchTCData?.data?.data.length-1; tcIdx >= 0; tcIdx--){
//                 tcTemps.push(wellAPI?.fetchTCData?.data?.data[tcIdx][i]);
//                 tcObject["data"]=tcTemps;
//                 tcObject["timestamp"]=wellAPI?.fetchTCData?.data?.timestamps[i];
//             }
//             console.log(tcObject.timestamp)
//             setSelectedTCs(tcObject);
//         }

//         if (selectedTrace && c.getTime() == selectedTrace.getTime()) {
//             return {time: c, zones: wellAPI.fetchZone.data[1].map(z => z[i])}
//         }
//         return p
//     })
//     }, [wellAPI.fetchZone.data, selectedTrace, currentTrace]);

//     // console.log([currentTrace, selectedTrace])
//     return  <>
//                 <div style={{height: "75vh"}}>
//                     <div id="TvDLayout">
//                     <TraceSelector traceSelected={(t) => setSelectedTrace(t)}/>
//                     <div id="TvDTopRow" className="TvDrow">
//                         <div id="topLeft" className="leftBar">
//                             <SelectedList
//                                 current={currentTrace}
//                                 selected={traces}
//                                 updateTrace={a => setTraces(a)}
//                             />
//                         </ div>
//                         <div id="topBar">
//                         </div>
//                         <div id="topRight" className="rightBar"></div>
//                     </div>

//                     <div id="TvDChartRow" className="TvDrow">
//                         <div id="leftBar" className="leftBar">
//                             <ChartSummary xLines={xLines} />
//                         </div>
//                         <div className="chartContent">
//                             <DepthTempChart
//                                 margins={margins}
//                                 traces={depthTraces}
//                                 xLim={depthLim}
//                                 yLim={tempLim}
//                                 tcs={selectedTCs}
//                                 // title={`${wellAPI.wellName} - ${selectedTrace}`}
//                             />
//                         </div>
//                         <div id="rightBar" className="rightBar">
//                         </div>
//                     </div>
//                     <div id="TvDBottomRow" className="TvDrow">
//                         <div id="bottomLeft" className="leftBar">
//                             {/* <HoverDataList
//                                 cursor={cursorData}
//                             />
//                         </div>
//                         <div id="bottomBar">
//                         </div>
//                         <div id="bottomRight" className="rightBar"></div>
//                     </div>
//                 </div>
//                 </div>
//             </>
//             */
// };
