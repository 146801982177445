import { useContext, useEffect } from "react"
import { ChartContext } from "../Canvas/ChartContext";
import { CanvasContext } from "../Canvas/SVGCanvasOverlay";

/*
{
  "key": 804,
  "data": [
    {
      "x": "2023-02-14T13:58:53Z",
      "y": 237.78,
      "colour": "rgba(255, 254, 0, 0)"
    }
  ]
}
*/

export const XLines = () => {
    const { xLines, xScale, height, width, margins } = useContext(ChartContext);
    const { clipId } = useContext(CanvasContext);

    const h1 = height - margins.bottom
    const h2 = margins.top;

    return <>
    <g style={{clipPath: `url(#${clipId})`}}>
        { xLines?.map((l,i) => 
          <rect 
            key={`xline_${l.key}`} 
            x={xScale(l.x)}
            y={h2}
            height={h1 - h2}
            // width={xScale(l.x + l.width) - xScale(l.x)} 
            width={isNaN(xScale(l.x + l.width) - xScale(l.x)) ? 3 : xScale(l.x + l.width) - xScale(l.x)}
            fill={l.colour}
            // fillOpacity={0.4}
           />) }
    </g>
    </>
}