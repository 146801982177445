import { useEffect, useState } from "react";
import { AuthProvider } from "react-oauth2-code-pkce";

import { ssodev, ssoprod, staging_strathcona, staging_cnrl } from "./azuread";
import pingConfig from "./pingconfig";
import  App from "./App";

const title = "Petrospec DTS Viz"

let loginProvider = ssodev;

if (process.env.REACT_APP_COMPANY === "cnrl") {
  console.log("cnrl");
  loginProvider = pingConfig;
} else if (process.env.REACT_APP_COMPANY === "strathcona") {
  console.log("strathcona");
  loginProvider = ssoprod;
} else if (process.env.REACT_APP_COMPANY === "staging_strathcona") {
  console.log("staging");
  loginProvider = staging_strathcona;
} else if (process.env.REACT_APP_COMPANY === "staging_cnrl") {
  console.log("staging");
  loginProvider = staging_cnrl;
} 

const SecuredApp = () => {
  useEffect(() => {
    document.title = title
  }, []);

  return <AuthProvider authConfig={loginProvider}>
      <App />
  </AuthProvider>
}

export default SecuredApp;
