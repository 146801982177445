import { Spin, Form, Row, Switch, Input, InputNumber, Typography, Button, Divider, Space } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import { WellAPI } from '../../../api';
import "./WellEdit.css";

import { FocusInput } from "../../../components/Well/FocusMode";
import { WellContext } from "../../../api/well";

const { Title } = Typography;

const WellEdit = () => {
    const params = useParams()
    const wellApi = WellAPI(params.sitename, params.wellname)
    const { fetchInfo, updateInfo } = wellApi;
    const [ form ] = Form.useForm();

    useEffect(() => {
        fetchInfo.sendRequest()
        return fetchInfo.cancel
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        form.setFieldsValue({"enabled": fetchInfo.data?.enabled})
    },[fetchInfo.data, form]);

    if ( fetchInfo.loading ) return <Spin />;

    const onFinish = (d) => updateInfo.sendRequest({data: d});

    console.log(fetchInfo.data)

    return <>
      <WellContext.Provider value={wellApi}>
        <PageHeader
            // onBack={() => null}
            onBack={() =>  window.location = `/site/${params.sitename}/${params.wellname}`}
            title={<>{params.sitename} - {params.wellname}</>}
        />
        <FocusInput />
            
        <Form form={form} onFinish={onFinish} wrapperCol={{flex : 1}}>
            <Form.Item label="Display Well" name="enabled" valuePropName="checked" initialValue={fetchInfo.data?.name}>
                <Switch />
            </Form.Item>
            <Divider orientation="left">Zones</Divider>
            { fetchInfo.data?.zones.map((z, i) => <ZoneInput key={`${params.wellname}_z${i}`} z={z} i={i} />)}
            <Divider orientation="left">Thermocouples</Divider>
            { fetchInfo.data?.tcs.map((tc, i) => <TCInput key={`${params.wellname}_tc${i}`} tc={tc} i={i} />)}
            <Divider orientation="left">Points of Interest</Divider>
            <POI data={fetchInfo.data?.poi}/>
            <Form.Item wrapperCol={{offset: 50, span: 50}}>
                <Button className="point-of-interest-btn" type="primary" htmlType="submit">
                    Save
                </Button>
            </Form.Item>
        </Form> 
        </WellContext.Provider>
    </>
}

const ZoneInput = ({z, i}) => {
    const zname = `z${i}`;

    return  <Row >
                <Form.Item label="Displayed" 
                    name={["zones", i, "displayed"]} 
                    valuePropName="checked" 
                    initialValue={z.displayed}
                    >
                    <Switch />
                </Form.Item>
                <Form.Item label={`${zname} name`} name={["zones", i, "name"]} initialValue={z.name ? z.name : zname}>
                    <Input />
                </Form.Item>
                <Form.Item label="Begin" name={["zones", i, "b"]} initialValue={z.b}>
                    <InputNumber />
                </Form.Item>
                <Form.Item label="End" name={["zones", i, "e"]} initialValue={z.e}>
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Current Max">
                    {z.mx.toFixed(1)} &deg;C
                </Form.Item>
           </ Row> 
}

const TCInput = ({tc, i}) => {
    const tcname = `tc${i}`;

    return  <Row >
                <Form.Item label="Displayed" 
                    name={["tcs", i, "displayed"]} 
                    valuePropName="checked" 
                    initialValue={tc.displayed}
                    >
                    <Switch />
                </Form.Item>
                <Form.Item label={`${tcname} name`} name={["tcs", i, "name"]} initialValue={tc.name || tcname}>
                    <Input />
                </Form.Item>
                <Form.Item label={`${tcname} depth`} name={["tcs", i, "loc"]} initialValue={tc.loc}>
                    <Input />
                </Form.Item>
                <Form.Item label="Current Temp">
                    {tc.tctemp.toFixed(1)} &deg;C
                </Form.Item>
                <Form.Item label="FO Temp">
                    {tc.fotemp.toFixed(1)} &deg;C
                </Form.Item>
           </ Row> 
}

const POI = ({data}) => {
    return  <Form.List
                name="poi"
                initialValue={data}
            >
                {(fields, {add, remove}, {errors}) => (<>                
                    {fields.map(({name, index, key}) => {
                    console.log(name)
                    return (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item label="name" name={[key, "name"]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Located at" name={[key, "depth"]} initialValue={0}>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label="end" name={[key, "end"]} initialValue={0}>
                            <InputNumber />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                    )
                    })}
                    <Form.Item>
                        <Button 
                            type="dashed"
                            onClick={() => {
                                add()
                            }}
                            style={{"width": "50%"}}
                            icon={<PlusOutlined />}>
                            Add POI
                        </Button>
                    </Form.Item>
                </>)}
            </Form.List>
}


export default WellEdit;