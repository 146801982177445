import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../api/user";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import {useDocumentTitle} from "../../CustomHooks/useDocumentTitle";

const UserHome = () => {
  const today = new Date()
  const curHr = today.getHours() 
  const [greetingText, setgreetingText] = useState('Hello, ')

  const user = useContext(UserContext);

  // Function to adjust Ryan's last name....feel free to remove this haha
  const adjustUserName = (name) => {
    if (name.includes('Vandrecht')) {
      return name.replace('Vandrecht', 'van Drecht');
    }
    return name;
  };

  useEffect(() => {
    // Deternine the appropriate greeting
    if (curHr < 12) {
      setgreetingText('Good Morning, ');
    } else if (curHr < 17) {
      setgreetingText('Good Afternoon, ');
    } else {
      setgreetingText('Good Evening, ');
    }
  }, []);


  const [documentTitle, setDocumentTitle] = useDocumentTitle("Home");

  // Adjust Ryan's name if necessary, everyone else stays as is :)
  const displayName = adjustUserName(user?.name);

  return (
    <div>
      <h1>{greetingText}{displayName}</h1>
      <div>
        <DashboardTable/>
      </div>
    </div>
  );
};

export { UserHome };