let colourCount = 0;

const colours = ["#001219", "#005F73", "#0A9396", "#64A29D", "#C9A886", "#EE9B00", "#CA6702", "#BB3E03", "#AE2012", "#9B2226"];

export const colourWheel = () => {
    colourCount += 1;

    if (colourCount >= colours.length) {
        colourCount = 0;
    }

    return colours[colourCount];
}
