import { Navigate } from "react-router";

import { Spin, Form, Button } from 'antd';

import { AuthContext } from "react-oauth2-code-pkce";
import { useContext } from "react";

import './index.css';

export const SignInButton = () => {
  const { token, login, loginInProgress } = useContext(AuthContext);

  // Stops the webpage from flickering while logging in
  if (loginInProgress) return <Spin />; 

  if (token) <Navigate to="\home" />;
  
  return <div className="login-panel">
          <Form className="login-form" >
          <Form.Item>
            <Button variant="secondary" onClick={login}>Login</Button>
            {/* <Button variant="secondary" onClick={login}>Petrospec Azure AD Login</Button> */}
          </Form.Item>
        </Form>
      </div>
}

export default SignInButton;
