import { useMemo, useCallback, useState, useEffect } from "react";
import { useResizer } from "./Hooks/Resizer";
import { SVGCanvasOverlay } from "./Canvas/SVGCanvasOverlay";

import * as d3 from "d3";

import { Title } from "./Labels/Title";
import { ChartContext } from "./Canvas/ChartContext";
import { XAxis } from "./Axes/XAxis";
import { YAxis } from "./Axes/YAxis";
import { CrossHairs } from "./Tooltip/Crosshairs";
import { PointOverlay } from "./Tooltip/Points";
import { XLines } from "./Tooltip/XLines";
import { TCPoints } from "./Trace/TC";
import { Line } from "./Trace/Line";

import { colourWheel } from "./Trace/Colours";
import { shortDate } from "../../utils/date";

const defaultMargins = {
    top: 20,
    bottom: 30,
    left: 60,
    right: 65 
}

export const ChartWithOverlay= ({
    title,
    margins=defaultMargins,
    xScaleType="linear",
    xLim,
    yLim,
    traces,
    xLines,
    setXLines,
    setCursorData,
    tcs,
    xUnit,
    yUnit,
    xLabel,
    onDoubleClick,
    xFormatter,
    yFormatter,
    zoneOverviewDepthTraces,
    children,
    handleWidthUpdate,
    useCrosshairs
}) => {
    const [ width, height, ref ] = useResizer();
    const [ cursor, setCursor ] = useState();
    const [persistzoneOverviewDepthTraces, setpersistzoneOverviewDepthTraces] = useState();

    // As soon as width is derived, call the provided callback.
    useEffect(() => {
        if (handleWidthUpdate) {
            handleWidthUpdate(width);
        }
    }, [width, handleWidthUpdate]);

    // Setup x Scale and x display format
    const xScale = useMemo(() => {
        if (!xLim) return;

        var scale;

        switch (xScaleType) {
            case "time":
                scale = d3.scaleTime();
                break;
            default:
                scale = d3.scaleLinear();
                break;
        }

        const s = scale
            .range([margins.left, width - margins.right])
            .domain(xLim)

        return s
    }, [width, margins, xLim]);

    // xScale.tickFormat() returns original tick formatter.... so here's my kludge

    const xFormat = useCallback(d => {
        if (xScaleType === "time") return shortDate(d);
        if (xFormatter) return xFormatter(d); 
        return `${Math.round(d)} ${xUnit}`;
    }, [xUnit, xScale, xFormatter]);

    // Setup y Scale and y display format

    const yScale = useCallback(yLim && d3.scaleLinear()
            .range([height - margins.bottom,  margins.top])
            .domain(yLim)
    , [height, margins, yLim] );

    const yFormat = useCallback(t => {
        if (yFormatter) return yFormatter(t);
        return `${Math.round(t)} ${yUnit}`;
    }, [yUnit, yScale, yFormatter]);

    const onCursorMove = useCallback((e) => {
        setCursor(e);
        
        // I had to stick this in there to get the data back outside the context provider
        if (traces.length && e) {
            const xData = traces[0].xData; 
    
            // Find the indices of the closest points to the left and right
            const leftIndex = d3.bisectLeft(xData, e.x) - 1;
            const rightIndex = d3.bisectRight(xData, e.x);
    
            // Determine the closest index
            let closestIndex;
            if (leftIndex < 0) {
                closestIndex = rightIndex;
            } else if (rightIndex >= xData.length) {
                closestIndex = leftIndex;
            } else {
                const leftDistance = Math.abs(xData[leftIndex] - e.x);
                const rightDistance = Math.abs(xData[rightIndex] - e.x);
                closestIndex = leftDistance <= rightDistance ? leftIndex : rightIndex;
            }
    
            // Set the cursor data using the closest index
            setCursorData && setCursorData(
                traces.map(t => ({
                    key: t.key, 
                    xVal: t.xData[closestIndex],
                    yVal: t.yData[closestIndex],
                    x: xFormat(t.xData[closestIndex]),
                    y: yFormat(t.yData[closestIndex]),
                    visible: t.visible,
                    colour: t.colour
                })).filter(t => t.visible)
            );
        } else {
            setCursorData && setCursorData(null);
        }
    }, [traces]);
    // }, [xScale, yScale, traces])

    useEffect(() => {
        // console.log("CHANGED zoneOverviewDepthTraces")
        if (!zoneOverviewDepthTraces) return;
        else {
            // console.log( {title}, {zoneOverviewDepthTraces})
            setpersistzoneOverviewDepthTraces(zoneOverviewDepthTraces);
        };
    }, [zoneOverviewDepthTraces]);

    const setXOverlay = useCallback((x) => {
        // console.log( {title}, {persistzoneOverviewDepthTraces}, {zoneOverviewDepthTraces})
        if (persistzoneOverviewDepthTraces) {
            const xVals = persistzoneOverviewDepthTraces[0].xData;
            const i = d3.bisect(xVals, x);
            // console.log({persistzoneOverviewDepthTraces})
            // console.log("adding a thing 2")
            setXLines && setXLines(e => {
                let a = [...e, 
                    {
                        key: xVals[i],
                        x: xVals[i],
                        xLabel: xFormat(xVals[i]),
                        // colour: colourWheel(), // This is where the xLines colours are set
                        width: 3,
                        data: traces.map(t => (
                            {
                                key: t.key,
                                y: t.yData[i],
                                yLabel: yFormat(t.yData[i]),
                                colour: t.colour,
                                visible: t.visible,
                                width: 3
                            }
                        ))
                    }];
                a.sort((a, b) => a.x > b.x ? 1 : -1)
                return a
           })
        }
        else if (traces.length) {
            const xVals = traces[0].xData;
            const i = d3.bisect(xVals, x);
            // console.log({traces})
            // console.log("adding a thing")
            setXLines && setXLines(e => {
                let a = [...e, 
                    {
                        key: xVals[i],
                        x: xVals[i],
                        xLabel: xFormat(xVals[i]),
                        colour: colourWheel(), // This is where the xLines colours are set
                        width: 3,
                        visible: true,
                        pin: false,
                        data: traces.map(t => (
                            {
                                key: t.key,
                                y: t.yData[i],
                                yLabel: yFormat(t.yData[i]),
                                colour: t.colour,
                                visible: t.visible,
                                width: 3
                            }
                        ))
                    }];
                a.sort((a, b) => a.x > b.x ? 1 : -1)
                return a
           })
        }
    }, [traces, setXLines, persistzoneOverviewDepthTraces])
    
    useEffect(() => {
        if (traces?.length && setXLines) {
            // console.log("NEW XLINE ADDED")
            setXLines(ex => {
                for (let d of ex) {
                    let newData = [];

                    for (let t of traces) {
                        const i = d3.bisect(t.xData, d.x);

                        newData.push(
                            {
                                key: t.key,
                                xVal: t.xData[i],
                                yVal: t.yData[i],
                                xLabel: xFormat(t.xData[i]),
                                yLabel: yFormat(t.yData[i]),
                                x: xFormat(t.xData[i]),
                                y: yFormat(t.yData[i]),
                                visible: t.visible,
                                colour: t.colour,
                                // width: 3
                            }
                        )
                    }

                    if(!d.data[0].xVal) {
                        d.data = newData; //This is overwriting the xLines and making the latest xLine data frigged
                    }
                }
            return [...ex]
            })
        }
    }, [traces])

    const scaledDoubleClick = useCallback((e) => {
        const bbox = e.target.getBoundingClientRect()
        const x = e.clientX - bbox.x + margins.left;
        const y = e.clientY - bbox.y + margins.top;

        if (traces.length && onDoubleClick) {
            const i = d3.bisect(traces[0].xData, xScale.invert(x))
            onDoubleClick(ex => {
                // console.log("EXISTING depthTraces:",ex)
                let arr = [...ex]; 
                let depths = arr.map(t => t.key);
                if (!depths.includes(traces[0].xData[i])) {
                    arr.push({
                    key: xFormat(traces[0].xData[i]),
                    x, 
                    y, 
                    xLabel: xFormat(traces[0].xData[i]),
                    xVal: traces[0].xData[i],
                    // colour: colourWheel(),
                    width: 3,
                    visible: true,
                    data: traces.map(t => (
                            {
                                key: t.key,
                                xVal: t.xData[i],
                                yVal: t.yData[i],
                                xLabel: xFormat(t.xData[i]),
                                yLabel: yFormat(t.yData[i]),
                                x: xFormat(t.xData[i]),
                                y: yFormat(t.yData[i]),
                                visible: t.visible,
                                colour: t.colour,
                                width: 3
                            }
                        ))
                    })
                arr.sort((a, b) => a.x > b.x ? 1 : -1)
                return arr 
            }
            })
        }
    }, [onDoubleClick, xScale, traces]);
    return  <div ref={ref} className="chart">
                <ChartContext.Provider value={{
                    height,
                    width,
                    margins,
                    title,
                    xScale,
                    yScale,
                    xFormat,
                    yFormat,
                    traces,
                    cursor,
                    xLines,
                    onCursorMove,
                    setXOverlay,
                }}>
                    <SVGCanvasOverlay onDoubleClick={scaledDoubleClick}>
                        <Title title={title} />
                        <XAxis />
                        <YAxis />
                        <PointOverlay />
                        {useCrosshairs && <CrossHairs xLabel={xFormat} />}
                        <XLines />
                        { traces?.map(t => t.visible && <Line key={t.key} trace={t} />) }
                        <TCPoints tcs={tcs} />
                        { children }
                    </SVGCanvasOverlay>
                </ChartContext.Provider>
            </div> 
}

