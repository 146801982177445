import { UserContext } from '../../api/user';

// import { useAuth } from 'react-oauth2-pkce';
import { AuthContext } from 'react-oauth2-code-pkce';

import { PartitionOutlined, HomeOutlined, UserOutlined, LogoutOutlined, HeartOutlined, DeploymentUnitOutlined, BugOutlined, CrownOutlined } from '@ant-design/icons';
import { BrowserRouter, Link } from 'react-router-dom';
import { useContext } from 'react';
import { Layout } from 'antd';

// import "antd/dist/antd.css";
import "./index.css";

import BasicHeader from "../BasicHeader";
import SideMenu from "../SideMenu";
import AppRoutes from "../../routing/AppRoutes";

//Just for testing
import WellAPI from '../../api/well';
import { WellContext } from '../../api/well';
import { SiteStatusTable } from '../../components/Status/SiteStatusTable';
import { TempVDepthLayout } from '../../components/Charts/Layouts';

const { Content, Footer } = Layout;


const BasicLayout = ({ 
    title
  }) => {

  // const authService = useAuth();
  const authContext = useContext(AuthContext);

  const user = useContext(UserContext);
  
  const padAccess = user ? user?.pads : [];
  const isAdmin = user?.admin;

  const headerComponents = [];

  let menuItems = [
    {
      value: 'Home',
      icon: <HomeOutlined />,
      key: 'home'
    },
    {
      key: 'siteDropdown', 
      value: 'Sites',
      icon: <PartitionOutlined />,
      subItems: padAccess?.map(p => ({key: "site/" + p, value: p}))
    },
    {
      key: 'healthreport',
      value: 'Health Report',
      icon: <HeartOutlined />,
    },
    {
      key: 'pdecstatus',
      value: 'PDEC Status',
      icon: <DeploymentUnitOutlined />,
    }]

	let dropdownItems = [
		{
			key: 'user',
			icon: <UserOutlined />,
			value: ' My Profile'
		},
		{
		  key: 'support',
		  icon: <BugOutlined style={{color:"green"}}/>,
		  value: ' Report Bug'
		},
		{
		  key: 'logout',
		  icon: <LogoutOutlined style={{color:"red"}}/>,
		  value: ' Logout',
		  callback: () => authContext.logOut()
		}
	  ]

  if (isAdmin) {
    dropdownItems.push( 
      {
        key: 'admin',
        icon: <CrownOutlined style={{color:"#9f8b4f"}}/>,
        value: <Link to="/admin"> Admin</Link>
      } 
    ) 
  }

  return (
      <BrowserRouter>
        <Layout style={{ minHeight: "100vh" }}>
          {menuItems && <SideMenu menuItems={menuItems} />}
          <Layout className="site-layout" style={{ padding: 16 }}>
            <BasicHeader
              title={title}
              menuItems={dropdownItems}
              user={user} >
              {headerComponents}
            </BasicHeader>
            <div className="site-layout-background">
            <Content>
              <AppRoutes />
            </Content>
            </div>
            <Footer />
          </Layout>
        </Layout>
      </BrowserRouter>
    );
}

export default BasicLayout;
