import axios from "axios";
// import { useAuth } from 'react-oauth2-pkce';

import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';

/* 
useApiClient returns an axios client

useAuth should update this client with fresh tokens
and add the Bearer token to the headers for all requests
*/

const defaultOpts = {
    baseURL: process.env.REACT_APP_BASE_API_URL, 
    headers: { 'Content-Type': 'application/json'}
}

const client = axios.create(defaultOpts);

const useApiClient = () => {
     const { 
       token, 
       error
     } = useContext(AuthContext)

     useEffect(() => {
        const interceptor = client.interceptors.request.use(request => {
            request.headers.common.Authorization = `Bearer ${token}`
            return request;
        })

        return () => client.interceptors.request.eject(interceptor)
     }, [token])

    return client;
}

export default useApiClient;