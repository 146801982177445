import Header from "../Header";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Table, Checkbox, Modal, Form } from "antd";
import { UserAPI } from "../../api";
import { useEffect, useState } from "react";
import { axisRight, nice } from "d3";
import { EditOutlined, ReloadOutlined } from "@ant-design/icons";

import ModalForm from "./ModalForm";

const Users = ({ users }) => {
  return (
    <div className="page-container" style={{ flexDirection: "column" }}>
      <Header title="Users">
        <ModalForm users={users}>
          <EditOutlined></EditOutlined>
        </ModalForm>
      </Header>
      <UserTable users={users} />
    </div>
  );
};

const UserTable = ({ users }) => {
  const [expandedKey, setExpandedKey] = useState(null);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // Aditional properties for sorting and filtering, can be used in combination with normal properties
      sort: "string",
      filter: "string",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",
      render: (k) => <Checkbox checked={k} />,
    },
  ];

  const onExpand = (_, { key }) => {
    if (expandedKey === key) {
      setExpandedKey(null);
    } else {
      setExpandedKey(key);
    }
  };

  const PadRowRender = (k) => {
    return (
      <Table
        dataSource={k.pads.map((p) => ({ name: p, key: p }))}
        columns={[
          {
            title: expandedKey + " Pad Access",
            dataIndex: "name",
            key: "name",
          },
        ]}
        pagination={false}
      />
    );
  };

  return (
    <Table
      dataSource={users?.map((u) => ({ ...u.data, key: u.data?.email }))}
      columns={columns}
      expandable={{
        expandedRowRender: PadRowRender,
        rowExpandable: (u) => !u.admin,
        onExpand: onExpand,
        expandedRowKeys: [expandedKey],
      }}
      pagination={false}
    ></Table>
  );
};

export default Users;
