import { useContext, useEffect } from "react";
import { ChartContext } from "../Canvas/ChartContext";
import { CanvasContext } from "../Canvas/SVGCanvasOverlay";
import * as d3 from "d3";

const drawYAxis = (
    context,
    scale,
    height,
    width,
    margins,
    tickFormat = (d) => d,
    tickSize = 6,
    fontSize = 10,
    ticks = 5,
    strokeStyle = "#2F2F2F",
    lineWidth = 1,
    threshold = 15
  ) => {
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth;
  
    context.fillStyle = "#000000";
  
    context.textAlign = "end";
    context.textBaseline = "middle";
  
    context.beginPath();
  
    context.moveTo(margins.left, margins.top);
    context.lineTo(margins.left, height - margins.bottom);
    context.font = `${fontSize}px sans-serif`;
  
    const fmt = tickFormat || scale.tickFormat();

    const yExtent = scale.domain();
    const extentDifference = yExtent[1] - yExtent[0];
    const tickSigFigs = (extentDifference > 10 ? 2 : 3);

    const yTicks = [yExtent[0]].concat(
      d3.ticks(yExtent[0], yExtent[1], 5),
      [yExtent[1]]
    );
  
    const isCloseToExtent = (tick, extentTicks, threshold) => {
      for (const extentTick of extentTicks) {
        if (Math.abs(scale(tick) - scale(extentTick)) < threshold) {
          return true;
        }
      }
      return false;
    };
  
    const extentTicks = [yTicks[0], yTicks[yTicks.length - 1]];
  
    for (let tick of yTicks) {
      context.moveTo(margins.left, scale(tick));
      context.lineTo(width - margins.right, scale(tick));
  
      const closeToExtent = isCloseToExtent(tick, extentTicks, threshold);
  
      if (!closeToExtent || extentTicks.includes(tick)) {
        context.font = `${fontSize}px sans-serif`;
        context.fillText(
          fmt(tick.toFixed(tickSigFigs)),
          margins.left - tickSize,
          scale(tick)
        );
      }
    }
  
    context.stroke();
  };

export const YAxis = () => {
    const { yScale, height, width, margins, yFormat } = useContext(ChartContext);
    const { canvas, renderFrame } = useContext(CanvasContext);

    useEffect(() => {
        if (!yScale || !canvas ) return;
        const ctx = canvas.getContext("2d");
        drawYAxis(ctx, yScale, height, width, margins, yFormat);
    // }, [ canvas, yScale, height, width, margins, renderFrame ])
    }, [ renderFrame ])

    return null 
}