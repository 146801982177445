import { useContext, useLayoutEffect, useEffect } from "react";
import { ChartContext } from "../Canvas/ChartContext";
import { CanvasContext } from "../Canvas/SVGCanvasOverlay";

export const Title = ({title, colour="black"}) => {
    const {canvas, renderFrame} = useContext(CanvasContext);
    const { margins, width } = useContext(ChartContext);

    useEffect(() => { 
        if ( !title || !canvas) return;
        const context = canvas.getContext("2d");

        context.save();

        context.fillStyle = colour;
        context.textAlign = "center";
        context.textBaseline = "middle"
        context.font = `16px Arial`;

        context.fillText(title, width/2, margins.top/2);

        context.restore();
    },[canvas, renderFrame, title, colour, width, margins]);

    return null
} 
