import React, { useContext, useState, useEffect, useCallback, useMemo, useRef } from "react";
import { WellContext } from "../../../api/well";

import { SelectedList } from "../Selectors/SelectedList";
import { ChartSummary } from "../Tooltip/Summary";
import { DepthTempChart } from "../DepthTempChart";
import { DepthSelector, TimeSelector } from "../Selectors";
import { useTempSelector, useCursorData } from "../Hooks";
import { Modal, Input, Button, List, Space, message } from "antd";

import dayjs from "dayjs";

import { Slider, InputNumber } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import convertTrace from "../convert";
import { depthLimits } from "../../../utils/depth";
import { adjustToBrowserTime } from "../../../utils/adjustToBrowserTime";
import { UserContext } from "../../../api/user";
import CSVDownload from "../../Misc/CSVDownload";

import { SchemaBarContainer } from "../SchemaBar";
import { transformAPISchemaSections } from "../../../utils/transformAPISchemaSections";
import { EditOutlined, LineChartOutlined, BgColorsOutlined} from "@ant-design/icons";
import { Tooltip } from "antd";
import { plotSection, plotPOI } from "../../../utils/plotSchemaSections";
import { useDBWellApi } from "../../../api/DBWellApi";




var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const styles = {
    container: {
      display: "grid",
      gridTemplateColumns: "300px 1fr 150px",
      gridTemplateRows: "250px 1fr 250px",
      height: "90vh",
    },
    leftColumn: {
      gridColumn: "1 / 2",
      gridRow: "1 / 4",
      backgroundColor: "#e6e6e6",
      textShadow: "0 0 0px rgba(255,255,255, 1)",
      fontWeight: "bold"
    },
    middleColumnTop: {
      gridColumn: "2 / 3",
      gridRow: "1 / 2",
    },
    chart: {
      gridColumn: "2 / 3",
      gridRow: "2 / 3",
      backgroundColor: "grey",
    },
    schemaBarContainer: {
        gridColumn: "2 / 3",
        gridRow: "3 / 4",
        backgroundColor: "#f2f2f2",
        position: 'relative',
        zIndex: 2,
        overflow: 'visible'
    },
    depthTrack: {
      gridColumn: "2 / 3",
      gridRow: "3 / 4",
      backgroundColor: "lightgrey",
    },
    tempTrack: {
      gridColumn: "3 / 4",
      gridRow: "2 / 3", 
      backgroundColor: "lightgrey", },
};
      
const margins = {
    top: 20,
    bottom: 70,
    left: 60,
    right: 60 
}

let traceMap = new Map();
let traceMapAll = new Map();

const TFOView = ({ onEditClick, wellSchema, activeTab
}) => {
    const { fetchMinMax, fetchTrace, queryTimestamps, fetchZone } = useContext(WellContext);
    const wellAPI = useContext(WellContext);
    const user = useContext(UserContext);

    const fetch = fetchTrace.copy();
    const query = queryTimestamps.copy();

    const [ xLines, setXLines ] = useState([]);

    const [ traces, setTraces ] = useState([]);
    const [ selectedTraces, setSelectedTraces ] = useState([]);

    const [ cursorData, setCursorData ] = useCursorData(traces);

    const [ tempLim, setTempLim, TempSelector ] = useTempSelector( traces, margins );
    const [ depthLim, setDepthLim ] = useState();

    const [ qty, setQty ] = useState(10);
    const [ timeMinMaxData, setTimeMinMaxData ] = useState([]);
    const [ timeRange, setTimeRange] = useState();

    const [ selectedTimeRange, setSelectedTimeRange ] = useState();

    const [ loading, setLoading ] = useState(false);

    const [timeSelectorTempLims, settimeSelectorTempLims] = useState();

    const [depthSelectorTempLims, setdepthSelectorTempLims] = useState([]);

    const [ loadedTimeRanges, setLoadedTimeRanges ] = useState([]);

    const [dateLim, setDateLim] = useState();

    const [isLoading, setIsLoading] = useState(false);

    //Schema sections states
    const [ schemaBarWidth, setSchemaBarWidth ] = useState();

    const [ schemaSections, setSchemaSections ] = useState(wellSchema);

    const [ showXLines, setShowXLines ] = useState(true);

    const [ toggledLayers, setToggledLayers ] = useState({}); //used for icon color change


    

    useEffect(() => {
        if (traces.length === 0) return;
        let latestTrace = traces[traces.length-1]
        if (!depthLim) {
            setDepthLim([latestTrace.xData[0], latestTrace.xData[latestTrace.xData.length-1]])
        } 
    },[traces.length])


    /* Schemasections effects & functions
    ##############################################################################################################*/

    //function to provide the schema bar with the appropriate width if the browser changes size
    function handleWidthUpdate(width) {
        setSchemaBarWidth(width);
    }


    useEffect(() => {
        wellAPI.fetchDepthOfInterest.sendRequest();
    }, []);

    useEffect(() => {
        //initialise schemaSections state from DB when page is loaded
        if (wellAPI.fetchDepthOfInterest.data && depthLim) {
            setSchemaSections(transformAPISchemaSections(wellAPI.fetchDepthOfInterest.data.allDepthOfInterest_DTO, depthLim));
        }
    }, [wellAPI.fetchDepthOfInterest.data]);

    // Update the const once the API call has been made to prevent overrriding on depthLim update
    const initialScheamaSectionsAPIcall = useRef(false);

    //updating the maxDepth and minDepth when the depthLim is updated (true flag)
    useEffect(() => {
        if(!depthLim) return;
        setSchemaSections(transformAPISchemaSections(schemaSections, depthLim, true));
    }, [ depthLim ]);

    //updating the schemaSections state from DB when page is loaded and depthLim is set
    useEffect(() => {
        if (initialScheamaSectionsAPIcall.current || !depthLim) {
            return;
        }

        if (wellAPI.fetchDepthOfInterest.data && depthLim) {
            setSchemaSections(transformAPISchemaSections(wellAPI.fetchDepthOfInterest.data.allDepthOfInterest_DTO, depthLim));
            initialScheamaSectionsAPIcall.current = true; //set to true so that API call is not triggered on every depthLim update
        }
    }, [ depthLim ]);

    //when wellSchema prop is updated in parent component, update schemaSections state
    useEffect(() => {
        setSchemaSections(wellSchema);
    }, [ wellSchema ]);

    // used for calculating the size of the schema bar
    const enabledSectionsCount = schemaSections.filter(sectionWrapper => sectionWrapper.isEnabled).length;
    const totalHeight = enabledSectionsCount * 20 + 10; // Adjusted to account for enabled sections

    //function to zoom in on a particular section when clicked on
    const onSectionClick = (depths) => {
        setDepthLim(depths);
    }

    // Function to handle the POI line toggling
    const handleToggleLines = () => {
        setShowXLines(!showXLines); // This will toggle between true and false
    };

    //Layers are the checkboxes for the well layers - can be selected to show/hide schema bars and plot points of interest
    //Keep in here for now for simplicity - Vlad
    const Layers = ({ schemaSections, toggleLayer}) => {
        return (
            <div>
                {schemaSections.map((sectionWrapper, idx) => (
                    <div key={idx}>
                        <input 
                            type="checkbox" 
                            checked={sectionWrapper.isEnabled} 
                            onChange={() => toggleLayer(idx)}
                        />
                        <label>{sectionWrapper.layerName}</label>
                        <Tooltip title='Plot Points of Interest' >
                            <LineChartOutlined 
                                onClick={() => plotPOI(sectionWrapper.sections, idx, xLines, setXLines, setToggledLayers)} 
                                style={{ fontSize: '18px', 
                                        paddingLeft: '5px', 
                                        color: toggledLayers[idx] ? 'white' : 'black' //change color for clickd layer
                                }}
                                />
                        </Tooltip>
                        <Tooltip title='Plot Sections' >
                            <BgColorsOutlined
                                onClick={() => plotSection(sectionWrapper.sections, idx, xLines, setXLines)}
                                style={{ fontSize: '18px', paddingLeft: '5px'  }} 
                            />
                        </Tooltip>
                    </div>
                ))}
            </div>
        );
    };

    //toggleLayer is used to toggle the schema layers on/off from view
    const toggleLayer = (index) => {
        const updatedSections = [...schemaSections];
        updatedSections[index].isEnabled = !updatedSections[index].isEnabled;
        setSchemaSections(updatedSections);
    };

    //##############################################################################################################

    useEffect(() => {
        if (query.data) {

            fetchZone.sendRequest({start: query.data[0], end: query.data[query.data.length - 1]})

            return () => {
                fetchZone.cancel();
            }
        }
    }, [query.data])

    useEffect(() => {
        if (timeRange) {
            setLoading(true)
            query.sendRequest({start: timeRange[0].toISOString(), end: timeRange[1].toISOString()})
            return () => {
                setLoading(false)
                query.cancel()
            }
        }
    }, [timeRange])

    useEffect(() => {
        if (timeRange) {
            fetchMinMax.sendRequest({start: timeRange[0].toISOString(), end: timeRange[1].toISOString()})
            return fetchMinMax.cancel
        }
    }, [timeRange])

    useEffect(() => {
        if (query.data && selectedTimeRange) {
            // const times = query.data.map(t => dayjs(t).utc().unix())
            const start = dayjs(selectedTimeRange[0])
            const end = dayjs(selectedTimeRange[1])

            const times = query.data.filter(t => dayjs(t).isBetween(start, end))

            if (!times.length) return; 

            let newTraces = [];

            const interval = (times.length - 1)/(qty - 1);

            if (qty === 1) {
                newTraces.push(times[0])
            } else if (qty >= times.length) {
                newTraces = times;
            } else {
                for (let i = 0; i < qty; i++) {
                    const index = Math.round(i*interval);
                    newTraces.push(times[index])
                }
            };

            setSelectedTraces(newTraces);
        }
    }, [query.data, qty, selectedTimeRange])

    useEffect(() => {
        if (selectedTraces) {
            const traces = selectedTraces.filter(t => traceMap.has(t)).map(t => traceMap.get(t));

            if (traces.length === 0) return;

            for (let i = 0; i < traces.length; i++) {
                const alpha = (i + 1) / traces.length * 0.8 + 0.2;
    
                // Check if the trace is the last one
                if (i === traces.length - 1) {
                    // Set a darker shade of red for the last trace
                    traces[i].colour = `rgba(139, 0, 0, ${alpha})`; // Dark red color
                } else {
                    traces[i].colour = `rgba(254, ${200 - 200 * (i + 1) / traces.length}, 0, ${alpha}`;
                }
    
                traces[i].width = 1.0;
            }

            traces[traces.length - 1].width = 1.5;

            setTraces(traces)
        }
    }, [loading, selectedTraces])

    useEffect(() => {
        const newTraces = selectedTraces.filter(t => !traceMap.has(t));

        if (newTraces.length) {
            setLoading(true)
            fetch.sendRequest({dtype:"temp", timestamp: newTraces.filter(t => !traceMap.has(t))})
            return () => {
                setLoading(false)
                fetch.cancel()
            }
        }
    }, [loading, selectedTraces])

    useEffect(() => {
        if (fetch.data) {
            fetch.data.forEach(t => traceMap.set(t.timestamp, convertTrace(t)))
            setLoading(false);
        }
    }, [fetch.data])

    useEffect(() => {
        if (fetchMinMax.data) {
            let minmax = fetchMinMax.data;
        
            setTimeMinMaxData(
                [{
                    key: "well_min",
                    xData: minmax.map(e => e.timestamp),
                    yData: minmax.map(e => e.min),
                    colour: "blue"
                },
                {
                    key: "well_max",
                    xData: minmax.map(e => e.timestamp),
                    yData: minmax.map(e => e.max),
                    colour: "red"
                }]
            )
        }
    }, [fetchMinMax.data])

    useEffect(() => {
        if (queryTimestamps.data) {
            setDateLim([
                dayjs(queryTimestamps.data[0]),
                dayjs(queryTimestamps.data[queryTimestamps.data.length - 1]),
            ]);
        }
    }, [queryTimestamps.data]);

    // Set the timeSelectorTempLims to always always have all traces fully in view within time section
    useEffect(() => {
        if (!traces) return;
        if (traces?.length === 0) return;
        if (!dateLim) return;
        let yIndexArr = [];

        let minValue = Infinity;
        let maxValue = -Infinity;
        let data = fetchMinMax.data;

        for(let i = 0; i < data.length; i++) {
            if(data[i].min < minValue) {
                minValue = data[i].min;
            }
            if(data[i].max > maxValue) {
                maxValue = data[i].max;
            }
        }

        if (minValue < -20) minValue = -20;

        settimeSelectorTempLims([minValue * 0.995, maxValue * 1.005]);
        
    }, [queryTimestamps, traces, dateLim]);

    // Set the templim to always always have all traces fully in view within TempVDepth depth section
    useEffect(() => {
        if(!traces) return;
        if(traces.length === 0) return;
        if (!depthLim) return;
        let yIndexArr = [];
        for (let i = 0; i < traces.length; i++) {
            let xDataArr = traces[i]["xData"];
            let yDataArr = traces[i]["yData"];
            let startIndex = xDataArr.indexOf(Math.round(depthLim[0]));
            let endIndex = (xDataArr.indexOf(Math.round(depthLim[1])) === -1) ? ((traces[0]?.xData?.length-1)) : (xDataArr.indexOf(Math.round(depthLim[1])));
            let tempXIndexArr = xDataArr.slice(startIndex, endIndex + 1);
            let tempYIndexArr = yDataArr.slice(startIndex, endIndex + 1);
            yIndexArr = yIndexArr.concat(tempYIndexArr);
   
        }
        let minValue = Math.min(...yIndexArr);
        let maxValue = Math.max(...yIndexArr);
        
        if (minValue < -20) minValue = -20;

        setTempLim([minValue*0.995, maxValue*1.005])
        if (depthSelectorTempLims.length === 0){
            setdepthSelectorTempLims([minValue, maxValue])
        }

    }, [depthLim, traces, timeSelectorTempLims]);  

    const onSliderChange = (newValue, number) => {
        setQty(newValue)
    }

    const onTimeRangeUpdate = useCallback((range) => {
        setTimeRange(range)
    }, [])

    const onTimeBrush = useCallback((range) => {
        // console.log("range TFO-Brush:", range)
        setSelectedTimeRange(range)
    }, [])


    const onDepthBrush = useCallback((depthRange) => {
        // only update if different
        setDepthLim(existing => { 
            for (let i in existing) {
                if (existing[i] !== depthRange[i]) {
                    return depthRange
                }
            }
            return existing
        })
    }, []);

    const selectedZone = useMemo(() => {
        if (!wellAPI.fetchZone.data) return;
        let timestamps = wellAPI.fetchZone.data[0]
        let zoneInfo = []

        for (let z = 0; z<=wellAPI.fetchZone.data[1].length; z++) {
            if (!wellAPI.fetchZone.data[1][z]) continue;
            zoneInfo.push(wellAPI.fetchZone.data[1][z][wellAPI.fetchZone.data[1][z].length-1])
        }
        
        let zonebarData = {
            time: timestamps[timestamps.length-1],
            zones: zoneInfo
        }
        return zonebarData
    }, [wellAPI.fetchZone.data, traces]);
    
    const depthExtents = useMemo(()=>{
        const depthExtents = depthLimits(traces[traces.length-1]);
        return depthExtents
    },[traces])

    const tempExtents = useMemo(() => {
        const tempLims = timeSelectorTempLims
        ? [
              Math.round(timeSelectorTempLims[0]),
              Math.round(timeSelectorTempLims[1]),
          ]
        : null
        return tempLims
    },[timeSelectorTempLims])

    
    //=========================================================================

    return <div style={styles.container}>
          <div style={styles.leftColumn}>
             <SelectedList 
                 selected={traces} 
                 updateTrace={a => setTraces([...a])}
             />
             <ChartSummary 
                data={cursorData}
                xData={xLines}
                updateXData={a => setXLines([...a])} 
              />
          </div>
          <div style={styles.middleColumnTop}>
                <TimeSelector 
                    margins={margins}
                    current={timeMinMaxData}
                    yLim={tempExtents}
                    onRangeUpdate={onTimeRangeUpdate}
                    onBrush={onTimeBrush}
                    showDatePicker={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    // selected={selectedTrace} 
                    // setSelectedTrace={setSelectedTrace}
                    // onTimeDrag={timeDrag} 
                    loadedTimeRanges={loadedTimeRanges}
                    view = "TFO"
                >
                    Number of Traces: 
                    <InputNumber min={1} max={30} value={qty} onChange={onSliderChange} />
                    <Slider style={{width: 300}} min={1} max={30} value={qty} onChange={onSliderChange} />
                </TimeSelector>
          </div>
          <div style={styles.chart}>
            <DepthTempChart 
                margins={margins}
                title={traces?.length ? adjustToBrowserTime(traces[traces.length - 1].key) : "No Data"}
                traces={traces}
                xLim={depthLim}
                yLim={tempLim}
                setCursorData={setCursorData}
                xLines={xLines}
                setXLines={setXLines}
                handleWidthUpdate={handleWidthUpdate}
            />
          </div>
            <div style={{...styles.topRight, marginTop: '40px'}}>
                <CSVDownload
                    type = "tfo"
                    viewer = "TFO"
                    timestamp={traces[traces?.length-1]?.key}
                    blob = { traces }
                    csvDataLoaded = {true}
                />
            </div>
          <div style={styles.depthTrack}>
            <div style={styles.schemaBarContainer}>
                <SchemaBarContainer //holds however many schema bars are stored in DB
                    schemaSections={schemaSections} 
                    totalHeight={totalHeight}
                    margins={margins}
                    schemaBarWidth={schemaBarWidth}
                    onSectionClick={onSectionClick}
                />
            </div>
            <DepthSelector
                key={depthSelectorTempLims}
                margins={margins}
                current={traces}
                onBrush={onDepthBrush}
                depthExtents={depthExtents}
                xLim={depthLim}
                yLim={depthSelectorTempLims.length === 0 ? tempLim : depthSelectorTempLims}
                zoneData={selectedZone}
            />
          </div>
            <div style={styles.tempTrack}>
                { TempSelector }
                <span style={{ fontWeight: 'bold' }}>Well Layers: <EditOutlined onClick={onEditClick} style={{ fontSize: '18px' }}/></span>
                    <Layers schemaSections={schemaSections} toggleLayer={toggleLayer} onToggleLines={handleToggleLines}/>
            </div>
        </div>
}

export default TFOView;
