import { useContext, useEffect, useState } from 'react';
import { UserContext } from "../../../api/user"
import useApiClient from "../../../api/useApi";
import { Card } from 'antd';
import './style.css'; 
import {useDocumentTitle} from '../../../CustomHooks/useDocumentTitle';
import { DepthLossChart } from '../../../components/Charts/DepthLossChart';

import convertLatest from '../../../components/Charts/convertLatest';

const margins = {top: 20, right: 60, bottom: 30, left: 60};

const depthGen = (rec) => {
    let arr = [];
    for (let i = rec.Start; i <= rec.Span + rec.Start; i += rec.Step) {
        arr.push(i)
    }
    return arr
}

const getLossPoints = (rec) => {
    const zero = -rec.Start;

    let conv = convertLatest(rec);
    conv.yData = rec.Loss;
    conv.colour = "red";

    console.log("conv", conv)

    return {
        timestamp: rec.Timestamp,
        connector: rec.Loss[31].toFixed(3),
        wellhead: (rec.Loss[zero + 10] - rec.Loss[zero]).toFixed(3),
        splice: (rec.Loss[zero] - rec.Loss[zero - 20]).toFixed(3),
        last: rec.Loss[rec.Loss.length - 10].toFixed(3),
        channel: rec.Chan,
        attenuation: Math.min(...rec.Cals.map(c => c.DiffAtt)).toFixed(4),
        x: [depthGen(rec)],
        y: [{label: rec.Timestamp, data: rec.Loss}],
        chart: <DepthLossChart 
                margins={margins}
                title={`${rec.Timestamp} ${rec.Name} Loss`}
                traces={[conv]}
                yLim={[Math.min(...conv.yData.filter(y => y > -20)), Math.max(...conv.yData)]}
                xLim={[rec.Start, rec.Start + rec.Span]}
                />

     }
}

const draw = (ctx, x, y, xTraces, yTraces) => {
    if (!xTraces || !yTraces || xTraces.length !== yTraces.length) return;
    for ( let i in xTraces) {
        const xData = xTraces[i];
        const yData = yTraces[i].data;

        if (!xData || !yData) return;

        const colour = yTraces[i].colour || "red"; 

        ctx.strokeStyle = colour;

        ctx.beginPath();

        ctx.moveTo(x(xData[0]), y(yData[0]))

        for ( let j in xData) {
            ctx.lineTo(x(xData[j]), y(yData[j]))
        }

        ctx.stroke()
    }
}

const healthColour = ( val, warn, danger) => {
    if (val > warn) return "green";
    if (val > danger) return "yellow";
    return "red";
}

const WellHealth = ({
    well,
    timestamp,
    connector,
    splice,
    wellhead,
    last,
    channel,
    attenuation,
    x,
    y,
    chart
}) => {
    const connStyle = {
        backgroundColor: healthColour(connector, -0.3, -0.7)
    }

    const spliceStyle = {
        backgroundColor: healthColour(splice, -1.2, -1.5) 
    }

    const wellheadStyle = {
        backgroundColor: healthColour(wellhead, -0.3, -0.5)
    }


    return <Card title={`${timestamp} ${well} Loss`}>
        {/* <div><b>DA: </b> {attenuation}</div> */}
        <div style={connStyle}><b>Connector:</b> {connector} dB</div>
        <div style={spliceStyle}><b>Across Splice:</b>{splice} dB</div>
        <div style={wellheadStyle}><b>Across WH:</b> {wellhead} dB </div>
        <div><b>Near toe:</b> {last} dB      </div>
        <div><b>Channel:</b>{channel}        </div>
        <div className="lossChart">
            { chart }
                {/* 
                traces={last}
                xLim={}
                yLim={lossLim}
                title={selectedTraceTimestamp ? `${wellAPI.wellName} - ${selectedTraceTimestamp}`: "No Data"}
                setCursorData={setCursorData}
                setXLines={setXLines} 
                xLines={xLines} 
                />
                */}
        </div> 
    </Card>
}

const HealthStatusPage = () => {
    const user = useContext(UserContext);
    const client = useApiClient();

    const [wellMap, setWellMap ] = useState(new Map());

    useEffect(() => {
        user?.pads.forEach(pad => {
          client.get(`/dts/site/${pad}`).then(rsp => {
            let loadedMap = new Map()
            rsp.data?.wells.forEach(well => {
                loadedMap.set(`${pad}_${well.name}`, getLossPoints(well.latest))
            })
            setWellMap(existing => new Map([...existing, ...loadedMap]))
          })
        })

    },  [ user ])

    const [documentTitle, setDocumentTitle] = useDocumentTitle("Health_Report");
    const sortedWellArray = Array.from(wellMap);
    sortedWellArray.sort((a, b) => a[0].localeCompare(b[0], undefined, {numeric:true}));
    const sortedWellMap = new Map(sortedWellArray);
    return <>{[...sortedWellMap.keys()].map(k => <WellHealth key={k} well={k} {...sortedWellMap.get(k)} />)}</>
}

export default HealthStatusPage; 