import { useState, useRef } from 'react';
import useApi from './useApi';

/* 
a Request object is a React component that returns react states 
data, loading, error
that update after the sendRequest async call completes or on failure

Request takes args that are passed to the axios.request, valid args can be found
https://axios-http.com/docs/req_config

sendRequest is the function that needs to be called to trigger the request, 
it accepts an object, that mostly get passed as URL search paramaters

for example:
sendRequest({range: "1mo"})
will pass that to the axios request as 
{
    params: {
        range: "1mo"
    }
}
and inject it into the url as https://endpoint?range=1mo

two exceptions are if you want to post data or override the url:
sendRequest({data: "mydata", url: "overridden.endpoint", myparam: "holla"})
would pass 
{
    url: "overridden.endpoint",
    data: "mydata",
    params: {
        "myparam": holla
    }
}
resulting in a url of http://overridden.endpoint?myparam=holla and "mydata" as the body of the request.

it also includes a cancel which is tied to an AbortController to cancel any current api calls, if you return
it at the end of the useEffect callback it will cancel any outstanding api requests or could be used to cancel
any long running api calls.

The intent of this is to be called inside a useEffect

Usage examples:

load when page renders

const SimpleUserDataComponent = () => {
    const userAPI = Request({url: "https://endpoint/user"})
    useEffect(() => userAPI.sendRequest(), [])

    return <>{userAPI.data?.name}{</>
}

Or if you wanted to do something more advanced

const AddComment = () => {
    const commentAPI = Request({
            url: "endpoint/comment",
            method: "post"
        })

    const [ comment, setComment ] = useState("");

  const handleSubmit = (evt) => {
      evt.preventDefault();
      commentAPI.sendRequest({data: comment});
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Comment:
        <input
          type="text"
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
      </label>
      <input type="submit" value="Submit" />
    </form>
  );
}

Or when perhaps changing a parameter should initiate a new request

const ViewDataLength = () => {
    const getDataAPI = Request({
        url: "http://endpoint/data",
        tranformResponse: data => data.map(record => record.myInterestingData)
    })

    const [ numRecordsToFetch, setNumRecords ] = useState(0);

    useEffect(() => {
        if (numRecordsToFetch > 0) {
            getDatAPI.sendRequest({records: numRecordsToFetch})
        }

        return getDataAPI.cancel
    }, [numRecordsToFetch])

    const onClick = (evt) => {
        setNumRecords( prevRecords => prevRecords += 1)
    }

    return (
        { getDataAPI.error && alert("Error", getDataAPI.error)}
        <h1>Records loaded: {getDataAPI.data?.length}<h1>
        { getDataAPI.loading && "loading data"}
        <button onClick={onClick}>Fetch one more record</button>
    )
}
*/

const ApiRequest = (args) => {
    const client = useApi();

    const [ data, setData ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const [ currentRequest, setCurrentRequest] = useState({});

    // controller to abort api requests 
    const controller = useRef(null);

    const sendRequest = async (reqArgs) => {
        if (loading) controller.current.abort();
        controller.current = new AbortController();

        // console.log("requesting", args, reqArgs)
        setLoading(true);
        setCurrentRequest(reqArgs)

        try {
            // one liner to strip out data and url and leave the rest of the keys for params
            const params =  reqArgs ? (({data, url, ...o}) => o)(reqArgs) : null;

            const result = await client.request({
                ...args,
                url: reqArgs?.url || args.url,
                params,
                signal: controller.current.signal,
                data: reqArgs?.data
            });

            setData(result.data);
            setError("");
        } catch (err) {
            setError(`Error ${args.url}: ${err} - ${err.response?.data || ''}`)
        } finally {
            setLoading(false)
        }
    }

    return {
        sendRequest,
        data,
        loading,
        currentRequest,
        error,
        args,
        copy: () => ApiRequest(args),
        cancel: () => {
            if (loading) {
                controller.current?.abort()
                console.log("canceling request", currentRequest)
            }
        }
    }
}

export default ApiRequest;