import ApiRequest from "./request"

export const SiteInfo = (site) => ApiRequest({url: `/dts/site/${site}`})

const UpdateSiteInfo = (site) => ApiRequest({
    url: `/dts/site/${site}/info`,
    method: 'post'
})

const SiteAPI = (site) => ({
    getSiteInfo: SiteInfo(site),
    updateSite: UpdateSiteInfo(site)
})

export default SiteAPI;