export const ssoprod = {
    redirectUri: "https://strathcona.petrospec.ca",
    logoutRedirectUri: "https://strathcona.petrospec.ca",
    scope: ["4ac0b16e-0a22-4282-9152-6afadb9cf466/dtsdata"],
    clientId: "4ac0b16e-0a22-4282-9152-6afadb9cf466",
    authorizationEndpoint: "https://login.microsoftonline.com/3b005e63-523d-4473-bc2f-ffcf716c0fcb/oauth2/v2.0/authorize", 
    tokenEndpoint: "https://login.microsoftonline.com/3b005e63-523d-4473-bc2f-ffcf716c0fcb/oauth2/v2.0/token",
    location: window.location,
}

export const ssodev = {
    redirectUri: "http://localhost:3000",
    logoutRedirectUri: "http://localhost:3000",
    scope: ["721d3e43-390f-473b-897c-d58307dea2a6/dtsdata"],
    clientId: "721d3e43-390f-473b-897c-d58307dea2a6",
    authorizationEndpoint: "https://login.microsoftonline.com/8303b49a-07b0-471b-bd78-0c1b2cb008db/oauth2/v2.0/authorize",
    tokenEndpoint: "https://login.microsoftonline.com/8303b49a-07b0-471b-bd78-0c1b2cb008db/oauth2/v2.0/token",
}

export const staging_strathcona = {
    redirectUri: "https://staging.strathcona.petrospec.ca",
    logoutRedirectUri: "https://staging.strathcona.petrospec.ca",
    scope: ["721d3e43-390f-473b-897c-d58307dea2a6/dtsdata"],
    clientId: "721d3e43-390f-473b-897c-d58307dea2a6",
    authorizationEndpoint: "https://login.microsoftonline.com/8303b49a-07b0-471b-bd78-0c1b2cb008db/oauth2/v2.0/authorize",
    tokenEndpoint: "https://login.microsoftonline.com/8303b49a-07b0-471b-bd78-0c1b2cb008db/oauth2/v2.0/token",
}

export const staging_cnrl = {
    redirectUri: "https://staging.cnrlpdis.petrospec.ca",
    logoutRedirectUri: "https://staging.cnrlpdis.petrospec.ca",
    scope: ["721d3e43-390f-473b-897c-d58307dea2a6/dtsdata"],
    clientId: "721d3e43-390f-473b-897c-d58307dea2a6",
    authorizationEndpoint: "https://login.microsoftonline.com/8303b49a-07b0-471b-bd78-0c1b2cb008db/oauth2/v2.0/authorize",
    tokenEndpoint: "https://login.microsoftonline.com/8303b49a-07b0-471b-bd78-0c1b2cb008db/oauth2/v2.0/token",
}
