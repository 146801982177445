import { useContext, useState, useEffect } from "react";
import { ChartContext } from "../Canvas/ChartContext";
import { depthGen } from "../../../utils/depth";
import * as d3 from "d3";

export const PointOverlay = () => {
    const [ points, setPoints ] = useState([]);

    const { xScale, yScale, traces, cursor } = useContext(ChartContext);

    useEffect(() => {
        if (!cursor || !traces?.length) {
            setPoints([]);
            return;
        }

        const i = d3.bisect(traces[0].xData, cursor.x)

        const pointList =  traces.filter(t => t.visible).map(t => ({
            xVal: t.xData[i],
            xPos: xScale(t.xData[i]),
            yVal: t.yData[i],
            yPos: yScale(t.yData[i]),
            colour: t.colour
        }))

        setPoints(pointList)
    }, [traces, cursor, xScale, yScale])

    return <>{points.map((p,i) => <circle key={`point_${i}`} stroke="red" fill={p.colour} cx={p.xPos} cy={p.yPos} r="5"/>)}</> 
}

