import { depthGen } from "../../utils/depth";
import { colourWheel } from "./Trace/Colours";


//Convert data from API to format for chart
const convertTrace = (trace, colour) => {
    if (!trace || !trace.timestamp || !trace.data.length ) return;

    // Find the last non -273.15 value starting from the end
    let lastIndex = trace.data.length - 1;
    while (lastIndex >= 0 && trace.data[lastIndex] === -273.15) {
        lastIndex--;
    }

    // Slice the array up to the last non -273.15 value
    const yDataFiltered = trace.data.slice(0, lastIndex + 1);

    // Adjust span based on the length of yDataFiltered
    const spanAdjusted = yDataFiltered.length;

    return {
        key: trace.timestamp,
        yData: yDataFiltered,
        xData: depthGen({
            start: trace.start,
            step:  trace.step,
            span:  spanAdjusted,  // Use the new adjusted span for xData, length of XData == YData
        }),
        colour: !trace.colour ? (colour || colourWheel()) : trace.colour,
        pin: false,
        visible: !trace.visible ? true : trace.visible
    }
}

 export default convertTrace; 